import React from "react";
import { connect } from "react-redux";

import { setOpponent } from "../redux/actions/getData";

import Connections from "../components/Connections";
import Chats from "../components/Chats";
import Dialog from "../components/Dialog";

const refList = React.createRef();

class IntroChat extends React.Component {
  componentDidMount() {
    this.scrollListener();
  }

  state = {
    scrollPosition: 0,
  };

  scrollListener = () => {
    const element = refList.current;
    if (element) {
      if (!element.getAttribute("listener")) {
        element.addEventListener("scroll", () => {
          this.setState({
            scrollPosition:
              element.scrollTop !== 0
                ? element.scrollTop
                : this.state.scrollPosition,
          });
        });
      }
    }
  };

  redirect = () => {
    if (!this.props.opponent) return false;

    const { history } = this.props;

    this.props.setOpponent("");
    return history.push(`/`);
  };

  render() {
    return (
      <div
        className="container-fluid"
        onKeyUp={(event) => (event.key === "Escape" ? this.redirect() : null)}
        tabIndex="0"
      >
        <div className="row">
          <div
            className={`col-12 col-md-4 d-block left-section anti-block overflow-custom ${
              this.props.opponent ? "mobile-section" : null
            } ${this.props.connections ? "pt-0" : null}`}
            ref={refList}
          >
            {this.props.connections ? (
              <Connections />
            ) : (
              <Chats
                profilePage={this.props.profilePage}
                menu={this.props.menu}
                scrollPosition={this.state.scrollPosition}
                maxScroll={
                  refList.current
                    ? refList.current.scrollHeight -
                      refList.current.clientHeight
                    : null
                }
              />
            )}
          </div>
          <div
            className={`col-12 col-md-8 d-block right-section ${
              this.props.opponent ? null : "mobile-section"
            }`}
          >
            <Dialog
              opponentProfile={this.props.opponentProfile}
              opponentMedia={this.props.opponentMedia}
              history={this.props.history}
            />
            {!this.props.opponent ? (
              <div className="empty-box anti-block">
                <div>
                  <span>Select a chat from left side to start.</span>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    opponent: state.user.opponent,
  };
};

const mapDispatchToProps = {
  setOpponent,
};

export default connect(mapStateToProps, mapDispatchToProps)(IntroChat);

import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Axios from "axios";
import CryptoJS from "crypto-js";
import Cookies from "universal-cookie";
import { Connect, Interactions, Listen } from "@respont/app";

import { ethers } from "ethers";
import Modal from "react-bootstrap/Modal";

import { API, chain } from "../constants/API";
import {
  setRespont,
  setInteractions,
  setListen,
} from "../redux/actions/getData";

const cookies = new Cookies();

class ModalMnemonic extends React.Component {
  state = {
    mnemonicSection: 1,
    password: "",
    repeatPassword: "",
    mnemonic: "",
    errorMessage: "",
    isWaiting: false,
  };

  componentDidMount() {
    this.setState({
      mnemonicSection: this.props.section === "import" ? 2 : 1,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.section !== this.props.section)
      this.setState({
        mnemonicSection: this.props.section === "import" ? 2 : 1,
        mnemonic: "",
        errorMessage: "",
      });
  }

  handleCreateToken = async (mnemonic) => {
    const enc = await Axios.post(`${API}/key/enc`, {
      key: btoa(mnemonic)
        .split("")
        .map((c) => {
          return c.charCodeAt(0);
        }),
    });

    let token = "";
    enc.data.data.forEach((c) => (token += String.fromCharCode(c)));

    let encryptedToken = CryptoJS.AES.encrypt(
      atob(token),
      this.state.password
    ).toString();

    cookies.set("token", encryptedToken, {
      path: "/password",
      maxAge: 172800,
      secure: true,
      sameSite: true,
    });

    localStorage.setItem("mnemonic", true);
  };

  handleGenerate = async (e) => {
    e.preventDefault();

    this.setState({
      isWaiting: true,
      errorMessage: "",
    });

    const wallet = ethers.Wallet.createRandom();
    const mnemonic = wallet.mnemonic.phrase;

    try {
      const respont = await new Connect(mnemonic, chain.rpcUrls[0]);

      if (!respont._wallets.storage._isSigner) return false;

      await this.handleCreateToken(mnemonic);
      this.props.setRespont(respont);

      const interact = new Interactions(respont, true);
      this.props.setInteractions(interact);

      const listen = new Listen(respont);
      this.props.setListen(listen);

      this.setState({
        password: "",
        repeatPassword: "",
        modalMnemonic: false,
        errorMessage: "",
        isWaiting: false,
      });
    } catch (e) {
      this.setState({
        errorMessage:
          "Oops, failed to generating a new mnemonic. Please try again later.",
        isWaiting: false,
      });
    }
  };

  handleImport = async (e) => {
    e.preventDefault();

    this.setState({
      errorMessage: "",
      isWaiting: true,
    });

    try {
      const respont = await new Connect(this.state.mnemonic, chain.rpcUrls[0]);

      if (!respont._wallets.storage._isSigner) return false;

      await this.handleCreateToken(this.state.mnemonic);
      this.props.setRespont(respont);

      const interact = new Interactions(respont, true);
      this.props.setInteractions(interact);

      const listen = new Listen(respont);
      this.props.setListen(listen);

      this.setState({
        password: "",
        repeatPassword: "",
        modalMnemonic: false,
        isWaiting: false,
      });
    } catch (e) {
      console.log(e);
      this.setState({
        errorMessage:
          "Oops, failed to importing mnemonic. Please try again later.",
        isWaiting: false,
      });
    }
  };

  render() {
    return (
      <Modal show={this.props.show} contentClassName="rounded-circle" centered>
        <div className="modal-mnemonic text-light">
          <div>
            <Link
              to={
                this.state.isWaiting
                  ? "#"
                  : `generate${
                      new URLSearchParams(window.location.search).get(
                        "destination"
                      )
                        ? `?destination=${new URLSearchParams(
                            window.location.search
                          ).get("destination")}`
                        : ""
                    }`
              }
            >
              <button
                className={`mx-1 rounded ${
                  this.state.mnemonicSection === 1 ? "active" : null
                }`}
              >
                Sign Up
              </button>
            </Link>
            <Link
              to={
                this.state.isWaiting
                  ? "#"
                  : `import${
                      new URLSearchParams(window.location.search).get(
                        "destination"
                      )
                        ? `?destination=${new URLSearchParams(
                            window.location.search
                          ).get("destination")}`
                        : ""
                    }`
              }
            >
              <button
                className={`mx-1 rounded ${
                  this.state.mnemonicSection === 2 ? "active" : null
                }`}
              >
                Sign In
              </button>
            </Link>
            <div className="mt-3">
              {this.state.mnemonicSection === 1 ? (
                <form onSubmit={this.handleGenerate}>
                  <h5 className="mb-3">Generate Key</h5>
                  <div className="alert bg-warning">
                    <i className="bi bi-exclamation-diamond-fill"></i>
                    <span className="text-dark">
                      We will never store your credentials. Your credential is
                      store in browser session. So, please keep your data
                      secret!
                    </span>
                  </div>
                  {this.state.errorMessage ? (
                    <div className="alert bg-danger">
                      <i className="bi bi-x-square-fill"></i>
                      <span className="text-light">
                        {this.state.errorMessage}
                      </span>
                    </div>
                  ) : null}
                  <p>Password</p>
                  <input
                    className="rounded mb-3"
                    type="password"
                    placeholder="Password"
                    onChange={(e) =>
                      this.setState({
                        password: e.target.value,
                      })
                    }
                    value={this.state.password}
                  />
                  <p>Repeat Password</p>
                  <input
                    className="rounded"
                    type="password"
                    placeholder="Repeat password"
                    onChange={(e) =>
                      this.setState({
                        repeatPassword: e.target.value,
                      })
                    }
                    value={this.state.repeatPassword}
                  />
                  {this.state.password !== this.state.repeatPassword ||
                  this.state.password.length <= 8 ? (
                    <span className="text-danger">
                      {this.state.password !== this.state.repeatPassword &&
                      this.state.repeatPassword.length > 0
                        ? "Password does not match!"
                        : this.state.password.length <= 8 &&
                          this.state.password.length > 0
                        ? "Password must more than 8 characters!"
                        : null}
                    </span>
                  ) : null}
                  <button
                    className={`${
                      this.state.isWaiting ? "text-muted" : null
                    } mt-3 mx-1 rounded ${
                      this.state.password.length > 8 &&
                      this.state.password === this.state.repeatPassword
                        ? null
                        : "cursor-no-drop text-muted"
                    }`}
                    disabled={
                      this.state.password.length > 8 &&
                      this.state.password === this.state.repeatPassword
                        ? this.state.isWaiting
                          ? true
                          : false
                        : true
                    }
                  >
                    {this.state.isWaiting ? "Generating" : "Generate"}
                  </button>
                  <Link
                    to={
                      this.state.isWaiting
                        ? "#"
                        : `/${
                            new URLSearchParams(window.location.search).get(
                              "destination"
                            )
                              ? new URLSearchParams(window.location.search).get(
                                  "destination"
                                )
                              : ""
                          }`
                    }
                  >
                    <button className="mt-3 mx-1 rounded">Close</button>
                  </Link>
                </form>
              ) : (
                <form onSubmit={this.handleImport}>
                  <h5 className="mb-3">Import Private Key / Mnemonic</h5>
                  <div className="alert bg-warning">
                    <i className="bi bi-exclamation-diamond-fill"></i>
                    <span className="text-dark">
                      We will never store your credentials. Your credential is
                      store in browser session. So, please keep your data
                      secret!
                    </span>
                  </div>
                  {this.state.errorMessage ? (
                    <div className="alert bg-danger">
                      <i className="bi bi-x-square-fill"></i>
                      <span className="text-light">
                        {this.state.errorMessage}
                      </span>
                    </div>
                  ) : null}
                  <p>Private Key / Mnemonic</p>
                  <textarea
                    className="rounded mb-1"
                    rows="4"
                    placeholder="Mnemonic phrase or EVM wallet private key."
                    onChange={(e) =>
                      this.setState({
                        mnemonic: e.target.value,
                      })
                    }
                    value={this.state.mnemonic}
                  />
                  {this.state.mnemonic.length > 0 &&
                  !(
                    this.state.mnemonic
                      .split(" ")
                      .filter((phrase) => phrase.length > 0).length %
                      3 ===
                      0 ||
                    (this.state.mnemonic.substring(0, 2) === "0x" &&
                      this.state.mnemonic.length === 66) ||
                    (this.state.mnemonic.substring(0, 2) !== "0x" &&
                      this.state.mnemonic.length === 64)
                  ) ? (
                    <span className="text-danger">
                      Wrong private key or mnemonic format.
                    </span>
                  ) : null}
                  <p className="mt-2">Password</p>
                  <input
                    className="rounded mb-3"
                    type="password"
                    placeholder="Password"
                    onChange={(e) =>
                      this.setState({
                        password: e.target.value,
                      })
                    }
                    value={this.state.password}
                  />
                  <p>Repeat Password</p>
                  <input
                    className="rounded"
                    type="password"
                    placeholder="Repeat password"
                    onChange={(e) =>
                      this.setState({
                        repeatPassword: e.target.value,
                      })
                    }
                    value={this.state.repeatPassword}
                  />
                  {this.state.password !== this.state.repeatPassword ||
                  this.state.password.length < 9 ? (
                    <span className="text-danger">
                      {this.state.password !== this.state.repeatPassword &&
                      this.state.repeatPassword.length > 0
                        ? "Password does not match!"
                        : this.state.password.length < 9 &&
                          this.state.password.length > 0
                        ? "Password must more than 8 characters!"
                        : null}
                    </span>
                  ) : null}
                  <button
                    className={`${
                      this.state.isWaiting ? "text-muted" : null
                    } mt-3 mx-1 rounded ${
                      this.state.password.length > 8 &&
                      this.state.password === this.state.repeatPassword &&
                      (this.state.mnemonic
                        .split(" ")
                        .filter((phrase) => phrase.length > 0).length %
                        3 ===
                        0 ||
                        (this.state.mnemonic.substring(0, 2) === "0x" &&
                          this.state.mnemonic.length === 66) ||
                        (this.state.mnemonic.substring(0, 2) !== "0x" &&
                          this.state.mnemonic.length === 64))
                        ? null
                        : "text-muted cursor-no-drop"
                    }`}
                    disabled={
                      this.state.password.length > 8 &&
                      this.state.password === this.state.repeatPassword &&
                      (this.state.mnemonic
                        .split(" ")
                        .filter((phrase) => phrase.length > 0).length %
                        3 ===
                        0 ||
                        (this.state.mnemonic.substring(0, 2) === "0x" &&
                          this.state.mnemonic.length === 66) ||
                        (this.state.mnemonic.substring(0, 2) !== "0x" &&
                          this.state.mnemonic.length === 64))
                        ? this.state.isWaiting
                          ? true
                          : false
                        : true
                    }
                  >
                    Import
                  </button>
                  <Link
                    to={
                      this.state.isWaiting
                        ? "#"
                        : `/${
                            new URLSearchParams(window.location.search).get(
                              "destination"
                            )
                              ? new URLSearchParams(window.location.search).get(
                                  "destination"
                                )
                              : ""
                          }`
                    }
                  >
                    <button className="mt-3 mx-1 rounded">Close</button>
                  </Link>
                </form>
              )}
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapDispatchToProps = {
  setRespont,
  setInteractions,
  setListen,
};

export default connect(null, mapDispatchToProps)(ModalMnemonic);

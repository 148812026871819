export const chain = {
  chainId: 17000,
  chainName: "Holesky",
  rpcUrls: ["https://ethereum-holesky-rpc.publicnode.com"],
  blockExplorerUrls: ["https://holesky.etherscan.io/"],
  nativeCurrency: {
    symbol: "ETH",
    decimals: 18,
  },
};
export const API = "https://server.respont.net";

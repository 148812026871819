import React from "react";
import { connect } from "react-redux";
import {
  setProfile,
  setProfiles,
  setOpponent,
  setOpponents,
  setBlockedList,
  autoUpdater,
  setLocalStorage,
  setSocketId,
  setConnection,
  setMessage,
} from "../redux/actions/getData";
import ReactTooltip from "react-tooltip";
import QRCode from "qrcode";
import { Link, withRouter } from "react-router-dom";
import { ethers } from "ethers";
import { Connect, Interactions } from "@respont/app";

import { chain } from "../constants/API";

import { init, subscribe, sync, profile } from "../redux/actions/Socket";
import avatarBroken from "../asset/avatar-broken.jpg";

import ListBox from "./List";
import ModalProfile from "./MyProfile";

class Chats extends React.Component {
  state = {
    search: "",
    inputNewChat: false,
    newChat: "",
    modalProfile: false,
    images: {},
    uploading: false,
    x: 0,
    y: 0,
    classList: "",
    qrOpen: false,
    isFetching: false,
    isZero: false,
  };

  componentDidMount() {
    if (this.props.opponents.length === 0) this.handleFetching();

    this.getProfile();
    this.handleAutoUpdate();
    this.QR();
    document.addEventListener("click", (e) => {
      try {
        let contextClass = document.getElementsByClassName("context-list");
        contextClass[0].classList.add("d-none");
        contextClass[0].classList.remove("d-block");

        if (this.state.classList) {
          let list = document.getElementsByClassName(
            `context-list-${this.state.classList}`
          );
          list[0].classList.remove("active");

          this.setState({
            classList: "",
          });
        }
      } catch (e) {}
    });
  }

  componentWillUnmount() {
    document.removeEventListener("click", (e) => {
      try {
        let contextClass = document.getElementsByClassName("context-list");
        contextClass[0].classList.add("d-none");
        contextClass[0].classList.remove("d-block");

        if (this.state.classList) {
          let list = document.getElementsByClassName(
            `context-list-${this.state.classList}`
          );
          list[0].classList.remove("active");

          this.setState({
            classList: "",
          });
        }
      } catch (e) {}
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.socketId !== this.props.socketId) this.QR();
    if (
      this.props.scrollPosition > this.props.maxScroll - 100 &&
      this.props.opponents.length > 0 &&
      !this.state.isFetching &&
      !this.state.isZero
    )
      this.handleFetching(
        this.props.opponents[this.props.opponents.length - 1].BlockHeight - 1
      );
  }

  getProfile = async () => {
    try {
      if (
        !this.props.connected ||
        (this.props.connected &&
          this.props.socketId &&
          this.props.room &&
          this.props.socketId === this.props.room)
      ) {
        const picture = await this.props.interactions.GetPicture(
          this.props.respont.address
        );

        this.props.setProfile(picture);
        if (this.props.connected) profile(picture);
      } else {
        profile();
      }
    } catch (e) {
      console.log(e);

      const respont = await new Connect(
        this.props.respont.mnemonic()
          ? this.props.respont.mnemonic()
          : this.props.respont.privateKey(),
        chain.rpcUrls[0]
      );
      this.props.setRespont(respont);

      const interact = new Interactions(respont, true);
      this.props.setInteractions(interact);

      this.getProfile();
    }
  };

  QR = () => {
    var canvas = document.getElementById("qr");
    if (this.props.socketId) {
      canvas.classList.add("d-block");
      canvas.classList.remove("d-none");
      QRCode.toCanvas(
        canvas,
        JSON.stringify({
          socketId: this.props.socketId,
          address: this.props.respont.address.toLowerCase(),
        }),
        {
          width: 210,
          color: {
            dark: "#000",
            light: "#F2F1F2",
          },
        }
      );
    } else {
      canvas.classList.add("d-none");
      canvas.classList.remove("d-block");
    }
  };

  handleFetching = async (BeforeHeight = 0) => {
    try {
      this.setState({ isFetching: true });

      let message = await this.props.interactions.Opponents(BeforeHeight);
      let blockedList = await this.props.interactions.GetBlocked();

      if (message.length === 0) {
        this.setState({ isZero: true });
      } else {
        this.setState({ isZero: false });
      }

      this.props.setBlockedList(blockedList);

      if (Array.isArray(message)) {
        if (this.props.localStorage.data) {
          if (this.props.localStorage.data.block) {
            const filterMessage = message.filter(
              (message) =>
                message.BlockHeight > this.props.localStorage.data.block &&
                message.FromAddress !== this.props.respont.address
            );

            let unreadJSON = {};

            if (this.props.localStorage.unreadMessage)
              unreadJSON = this.props.localStorage.unreadMessage;

            filterMessage.forEach((message) => {
              if (unreadJSON[message.Opponent]) {
                unreadJSON[message.Opponent.toLowerCase()] = {
                  ...unreadJSON[message.Opponent],
                  block: this.props.localStorage.data.block,
                };
              } else {
                unreadJSON[message.Opponent.toLowerCase()] = {
                  count: 0,
                  block: this.props.localStorage.data.block,
                  markUnread: false,
                };
              }
            });

            this.props.setLocalStorage("unreadMessage", unreadJSON);
          }
        }

        if (BeforeHeight > 0) {
          message = [...this.props.opponents, ...message];
        }

        this.props.setOpponents(message);
      }

      this.setState({ isFetching: false });
    } catch (e) {
      console.log(e);

      const respont = await new Connect(
        this.props.respont.mnemonic()
          ? this.props.respont.mnemonic()
          : this.props.respont.privateKey(),
        chain.rpcUrls[0]
      );
      this.props.setRespont(respont);

      const interact = new Interactions(respont, true);
      this.props.setInteractions(interact);

      this.handleFetching(BeforeHeight);
    }
  };

  handleUnread = (Sender) => {
    let unreadJSON;
    if (!this.props.localStorage.unreadMessage) {
      unreadJSON = {};
      unreadJSON[Sender] = {
        block: this.props.localStorage.data.block,
        markUnread: false,
      };
    } else {
      if (!this.props.localStorage.unreadMessage[Sender]) {
        unreadJSON = this.props.localStorage.unreadMessage;
        unreadJSON[Sender] = {
          block: this.props.localStorage.data.block,
          markUnread: true,
        };
      }
    }
    this.props.setLocalStorage("unreadMessage", unreadJSON);

    sync("unreadMessage", unreadJSON);
  };

  handleAutoUpdate = async () => {
    if (
      !this.props.connected ||
      (this.props.connected &&
        this.props.socketId &&
        this.props.room &&
        this.props.socketId === this.props.room)
    ) {
      this.props.listen.on("Sent", async (Sender, Receiver) => {
        if (
          Receiver.toLowerCase() === this.props.respont.address.toLowerCase() ||
          Sender.toLowerCase() === this.props.respont.address.toLowerCase()
        ) {
          let opponent;
          if (
            Receiver.toLowerCase() === this.props.respont.address.toLowerCase()
          ) {
            opponent = Sender.toLowerCase();
          } else {
            opponent = Receiver.toLowerCase();
          }

          let message;
          try {
            message = await this.props.interactions.AMessage(opponent);
          } catch (e) {
            console.log(e);

            const respont = await new Connect(
              this.props.respont.mnemonic()
                ? this.props.respont.mnemonic()
                : this.props.respont.privateKey(),
              chain.rpcUrls[0]
            );
            this.props.setRespont(respont);

            const interact = new Interactions(respont, true);
            this.props.setInteractions(interact);

            message = await this.props.interactions.AMessage(opponent);
          }

          if (
            message.FromAddress.toLowerCase() ===
              this.props.respont.address.toLowerCase() &&
            this.props.messages[opponent]
          ) {
            let messages = this.props.messages[opponent];
            const pendingMessages = this.props.messages[opponent].findIndex(
              (pending) =>
                message.MessageText === pending.MessageText && pending.isPending
            );
            messages.splice(pendingMessages, 1);

            this.props.setMessage(opponent, messages);
          }

          let opponents = [];
          if (this.props.opponents.length > 0) {
            opponents = this.props.opponents.filter(
              (opponents) =>
                opponents.FromAddress.toLowerCase() !== opponent &&
                opponents.ToAddress.toLowerCase() !== opponent
            );
          }
          opponents.push(message);

          opponents = opponents.sort(
            (a, b) => b.MessageTimestamp - a.MessageTimestamp
          );
          this.props.setOpponents(opponents);

          let messages = this.props.messages[opponent];
          if (messages) {
            messages.push(message);
          } else {
            messages = [message];
          }

          if (
            this.props.opponent.toLowerCase() !== opponent &&
            message.FromAddress !== this.props.respont.address
          ) {
            let unreadMessages = this.props.localStorage.unreadMessage;

            if (!unreadMessages) unreadMessages = {};

            unreadMessages[opponent] = {
              block: message.BlockHeight,
              markUnread: true,
            };

            this.props.setLocalStorage("unreadMessage", unreadMessages);
          }

          this.props.setMessage(opponent, messages);
        }
      });

      this.props.listen.on("PictureChanged", async (Address, MediaLink) => {
        if (
          Address.toLowerCase() === this.props.respont.address.toLowerCase()
        ) {
          this.props.setProfile(MediaLink);
        } else if (
          this.props.opponents.findIndex(
            (address) => Address.toLowerCase() === address[0].toLowerCase()
          ) >= 0
        ) {
          this.props.setProfiles(Address, MediaLink);
        }
      });

      this.props.listen.on("BlockListAdded", (Owner, Address) => {
        if (Owner.toLowerCase() === this.props.respont.address.toLowerCase()) {
          let blockedList = new Array(Address);
          blockedList = blockedList.concat(Address, this.props.blocked);
          blockedList = [...new Set(blockedList)];
          this.props.setBlockedList(blockedList);
        }
      });

      this.props.listen.on("BlockListRemoved", (Owner, Address) => {
        if (Owner.toLowerCase() === this.props.respont.address.toLowerCase())
          this.props.setBlockedList(
            this.props.blocked.filter(
              (address) => address.toLowerCase() !== Address.toLowerCase()
            )
          );
      });

      this.props.listen.on("block", (block) => {
        let detailData;
        if (this.props.localStorage.data)
          detailData = this.props.localStorage.data;

        detailData = { block };

        this.props.setLocalStorage("data", detailData);

        sync("data", detailData);
      });
    }
  };

  searchInputHandler = (event) => {
    const value = event.target.value;

    this.setState({ search: value });
  };

  newChatInputHandler = (event) => {
    const value = event.target.value;

    this.setState({ newChat: value });
  };

  newChat = (event) => {
    event.preventDefault();

    if (
      ethers.utils.isAddress(event.target[0].value) &&
      event.target[0].value.toLowerCase() !==
        this.props.respont.address.toLowerCase()
    ) {
      const { history } = this.props;

      history.push(`/${event.target[0].value}`);
      event.target[0].value = "";

      this.setState({
        inputNewChat: false,
      });
    }
  };

  renderList = () => {
    let search = this.state.search.toLowerCase().replace(/\s/g, "");

    if (
      search.length > 0 &&
      this.props.respont.address.toLowerCase() !== search.toLowerCase()
    ) {
      let filter = this.props.opponents.filter(
        (filterOne) =>
          filterOne.FromAddress.toLowerCase().includes(search) &&
          !filterOne.blocked
      );
      if (filter.length > 0) {
        return filter.map((val) => {
          return (
            <div
              onContextMenu={(e) =>
                this.showOption(
                  e,
                  val.FromAddress.toLowerCase() ===
                    this.props.respont.address.toLowerCase()
                    ? val.ToAddress.toLowerCase()
                    : val.FromAddress.toLowerCase()
                )
              }
              className={`context-list-${
                val.FromAddress.toLowerCase() ===
                this.props.respont.address.toLowerCase()
                  ? val.ToAddress.toLowerCase()
                  : val.FromAddress.toLowerCase()
              }`}
            >
              <ListBox
                FromAddress={val.FromAddress}
                ToAddress={val.ToAddress}
                MessageTimestamp={val.MessageTimestamp}
                MessageText={val.MessageText}
                MediaLink={val.MediaLink}
              />
            </div>
          );
        });
      }
    } else {
      return (
        <div>
          {this.props.opponents
            .filter((filter) => !filter.blocked)
            .map((val, i) => {
              return (
                <>
                  <div
                    onContextMenu={(e) =>
                      this.showOption(
                        e,
                        val.FromAddress.toLowerCase() ===
                          this.props.respont.address.toLowerCase()
                          ? val.ToAddress.toLowerCase()
                          : val.FromAddress.toLowerCase()
                      )
                    }
                    className={`context-list-${
                      val.FromAddress.toLowerCase() ===
                      this.props.respont.address.toLowerCase()
                        ? val.ToAddress.toLowerCase()
                        : val.FromAddress.toLowerCase()
                    }`}
                  >
                    <ListBox
                      FromAddress={val.FromAddress}
                      ToAddress={val.ToAddress}
                      MessageTimestamp={val.MessageTimestamp}
                      MessageText={val.MessageText}
                      MediaLink={val.MediaLink}
                    />
                  </div>
                </>
              );
            })}
        </div>
      );
    }
  };

  showOption = (e, Opponent) => {
    let name = document.getElementsByClassName("context-list");
    name[0].classList.add("d-block");
    name[0].classList.remove("d-none");

    let list = document.getElementsByClassName(`context-list-${Opponent}`);
    list[0].classList.add("active");

    let x = e.clientX;
    let y = e.clientY;

    if (x + 200 > window.innerWidth) x -= 200;
    if (y + 100 > window.innerHeight) y -= 100;

    if (this.state.classList) {
      let list = document.getElementsByClassName(
        `context-list-${this.state.classList}`
      );
      list[0].classList.remove("active");
    }

    this.setState({
      x,
      y,
      classList: Opponent,
    });
  };

  handleMark = () => {
    if (
      this.state.x > 0 &&
      this.state.y > 0 &&
      this.state.classList &&
      this.state.classList.toLowerCase() !== this.props.opponent.toLowerCase()
    ) {
      let unreadJSON;
      if (!this.props.localStorage.unreadMessage) {
        unreadJSON = {};
        unreadJSON[this.state.classList.toLowerCase()] = {
          block: -1,
          markUnread: true,
        };
      } else {
        if (
          this.props.localStorage.unreadMessage[
            this.state.classList.toLowerCase()
          ]
        ) {
          if (
            this.props.localStorage.unreadMessage[
              this.state.classList.toLowerCase()
            ].markUnread
          ) {
            unreadJSON = this.props.localStorage.unreadMessage;
            delete unreadJSON[this.state.classList.toLowerCase()];
          } else {
            unreadJSON = this.props.localStorage.unreadMessage;
            unreadJSON[this.state.classList.toLowerCase()] = {
              ...unreadJSON[this.state.classList],
              markUnread: true,
            };
          }
        } else {
          unreadJSON = this.props.localStorage.unreadMessage;
          unreadJSON[this.state.classList.toLowerCase()] = {
            block: -1,
            markUnread: true,
          };
        }
      }

      this.props.setLocalStorage("unreadMessage", unreadJSON);

      sync("unreadMessage", unreadJSON);
    }
  };

  handleBlock = async () => {
    try {
      await this.props.interactions.AddBlockList(this.state.classList);
    } catch (e) {
      console.log(e);

      const respont = await new Connect(
        this.props.respont.mnemonic()
          ? this.props.respont.mnemonic()
          : this.props.respont.privateKey(),
        chain.rpcUrls[0]
      );
      this.props.setRespont(respont);

      const interact = new Interactions(respont, true);
      this.props.setInteractions(interact);

      this.handleBlock();
    }
  };

  showQR = () => {
    this.setState({ qrOpen: true });

    init();
    subscribe();
    sync();
  };

  render() {
    return (
      <>
        <ModalProfile
          show={this.props.profilePage ? true : false}
          menu={this.props.menu}
        />
        <div
          className="context-list rounded shadow-sm"
          style={{ top: this.state.y, left: this.state.x }}
        >
          {this.state.classList ? (
            <>
              <div onClick={this.handleMark} className="cursor-pointer">
                <i className="bi bi-envelope-paper"></i> Mark as read / unread
              </div>
              <div className="cursor-pointer text-muted cursor-no-drop">
                <i className="bi bi-pin-angle"></i> Pin chat
              </div>
              <div onClick={this.handleBlock} className="cursor-pointer">
                <i className="bi bi-x-lg"></i> Block Address
              </div>
            </>
          ) : null}
        </div>

        <div
          className={`qr-box qr-class rounded shadow ${
            this.state.qrOpen ? "d-block" : "d-none"
          }`}
        >
          <b className="qr-class">Open on Other Devices</b>
          <i
            className="bi bi-x-lg text-dark cursor-pointer"
            title="Close"
            onClick={() => this.setState({ qrOpen: false })}
          ></i>
          <span className="qr-class">
            Scan on your other devices for realtime data communication.
          </span>
          <div className="qr-class">
            <canvas id="qr" className="qr-class"></canvas>
          </div>
        </div>

        <div>
          <div className="row">
            <div className="section-head col-md-4 position-fixed">
              <div className="profile-box">
                {this.props.connection.find((e) => !e.connected) ? (
                  <i className="bi bi-circle-fill text-warning"></i>
                ) : null}
                <Link to="profile">
                  <img
                    src={this.props.profile ? this.props.profile : avatarBroken}
                    alt={this.props.respont.address.toLowerCase()}
                    className={`rounded-circle settings-box ${
                      this.props.connection.find((e) => !e.connected)
                        ? "border-warning"
                        : null
                    }`}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = avatarBroken;
                    }}
                    onDragStart={(event) => event.preventDefault()}
                  />
                </Link>
                <div>
                  <ReactTooltip
                    id="copy-text"
                    place="bottom"
                    effect="solid"
                    type="light"
                    className={"copy-tooltip"}
                  >
                    Click to Copy
                  </ReactTooltip>
                  <p
                    className="cursor-pointer"
                    data-tip
                    data-for="copy-text"
                    onClick={(e) =>
                      navigator.clipboard.writeText(e.target.textContent)
                    }
                  >
                    {this.props.respont.address.toLowerCase()}
                  </p>
                </div>
              </div>
              <hr />
              <div className="search-box">
                <i className="bi bi-search text-white"></i>
                <input
                  type="text"
                  placeholder="Search address..."
                  name="search"
                  onChange={this.searchInputHandler}
                />
              </div>
            </div>
            <div className="more-action d-flex justify-content-between">
              <button
                className="btn text-light"
                onClick={() =>
                  this.setState({ inputNewChat: !this.state.inputNewChat })
                }
              >
                <i className="bi bi-send-plus me-3"></i>New message
              </button>

              {/* {this.props.opponents.filter((filter) => !filter.blocked).length >
              0 ? (
                <button className="btn text-light">
                  <i className="bi bi-filter me-2"></i> Sort
                </button>
              ) : null} */}
            </div>
            {this.state.inputNewChat ? (
              <div className="new-chat mt-2">
                <div className="search-box">
                  <i
                    className={`bi bi-send-plus ${
                      this.state.newChat &&
                      !ethers.utils.isAddress(this.state.newChat)
                        ? "text-danger"
                        : "text-light"
                    }`}
                  ></i>
                  <form className="w-100" onSubmit={this.newChat}>
                    <input
                      type="text"
                      placeholder="Type address..."
                      name="search"
                      className={
                        this.state.newChat &&
                        !ethers.utils.isAddress(this.state.newChat)
                          ? "text-danger"
                          : ""
                      }
                      onChange={this.newChatInputHandler}
                    />
                  </form>
                </div>
              </div>
            ) : null}
            {this.renderList()}
            {this.state.isFetching ? (
              <div className="w-100 text-center">
                <div className="loader"></div>
              </div>
            ) : null}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    respont: state.user.respont,
    interactions: state.user.interactions,
    listen: state.user.listen,
    profile: state.user.profile,
    opponent: state.user.opponent,
    opponents: state.user.opponents,
    messages: state.user.messages,
    pendingMessages: state.user.pendingMessages,
    localStorage: state.user.localStorage,
    socketId: state.user.socketId,
    connection: state.user.connection,
    connected: state.user.connected,
    room: state.user.room,
    blocked: state.user.blocked,
  };
};

const mapDispatchToProps = {
  setProfile,
  setProfiles,
  setOpponent,
  setOpponents,
  setBlockedList,
  autoUpdater,
  setLocalStorage,
  setSocketId,
  setConnection,
  setMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Chats));

import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { profiles } from "../redux/actions/Socket";
import { Connect, Interactions } from "@respont/app";

import {
  setOpponent,
  setLocalStorage,
  setProfiles,
} from "../redux/actions/getData";
import { chain } from "../constants/API";

import avatarBroken from "../asset/avatar-broken.jpg";

class List extends React.Component {
  state = {
    opponent:
      this.props.FromAddress.toLowerCase() ===
      this.props.respont.address.toLowerCase()
        ? this.props.ToAddress.toLowerCase()
        : this.props.FromAddress.toLowerCase(),
  };

  async componentDidMount() {
    this.getPicture();
  }

  getPicture = async () => {
    try {
      if (this.props.ToAddress in this.props.profiles) return true;

      if (
        !this.props.connected ||
        (this.props.connected &&
          this.props.socketId &&
          this.props.room &&
          this.props.socketId === this.props.room)
      ) {
        const picture = await this.props.interactions.GetPicture(
          this.props.ToAddress
        );
        this.props.setProfiles(this.props.ToAddress, picture);

        if (this.props.connected) profiles(this.props.profiles);
      } else {
        profiles();
      }
    } catch (e) {
      console.log(e);

      const respont = await new Connect(
        this.props.respont.mnemonic()
          ? this.props.respont.mnemonic()
          : this.props.respont.privateKey(),
        chain.rpcUrls[0]
      );
      this.props.setRespont(respont);

      const interact = new Interactions(respont, true);
      this.props.setInteractions(interact);

      this.getPicture();
    }
  };

  render() {
    return (
      <Link
        to={
          this.props.FromAddress.toLowerCase() ===
          this.props.respont.address.toLowerCase()
            ? this.props.ToAddress
            : this.props.FromAddress
        }
      >
        <div
          className={`list-box ${
            this.props.ToAddress.toLowerCase() ===
              this.props.opponent.toLowerCase() ||
            this.props.FromAddress.toLowerCase() ===
              this.props.opponent.toLowerCase()
              ? "active"
              : null
          }`}
          onClick={() =>
            this.props.setOpponent(
              this.props.FromAddress.toLowerCase() ===
                this.props.respont.address.toLowerCase()
                ? this.props.ToAddress
                : this.props.FromAddress
            )
          }
        >
          <img
            src={this.props.profiles[this.props.ToAddress]}
            alt={this.props.ToAddress}
            className="rounded-circle"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = avatarBroken;
            }}
            onDragStart={(event) => event.preventDefault()}
          />
          <div className="right">
            <div
              className={`opponent ${this.props.FromAddress ? null : "mt-3"}`}
            >
              <p
                className={`address ${
                  this.props.FromAddress ? null : "fw-bold"
                }`}
                title={
                  this.props.FromAddress.toLowerCase() ===
                  this.props.respont.address.toLowerCase()
                    ? this.props.ToAddress
                    : this.props.FromAddress
                }
              >
                {this.props.FromAddress.toLowerCase() ===
                this.props.respont.address.toLowerCase()
                  ? this.props.ToAddress
                  : this.props.FromAddress}
              </p>
              <p className="time text-secondary">
                {this.props.FromAddress
                  ? this.props.MessageTimestamp >
                    parseInt(Date.now() / 1000, 0) - 86400
                    ? moment.unix(this.props.MessageTimestamp).format("hh:mma")
                    : this.props.MessageTimestamp >
                      parseInt(Date.now() / 1000, 0) - 172800
                    ? "Yesterday"
                    : moment
                        .unix(this.props.MessageTimestamp)
                        .format("MM/DD/YYYY")
                  : null}
              </p>
            </div>
            {this.props.FromAddress ? (
              <div className="preview" title={this.props.MessageText}>
                <p
                  className={
                    this.props.localStorage.unreadMessage
                      ? this.props.localStorage.unreadMessage[
                          this.props.ToAddress.toLowerCase()
                        ] &&
                        (this.props.localStorage.unreadMessage[
                          this.props.ToAddress.toLowerCase()
                        ].count > 0 ||
                          this.props.localStorage.unreadMessage[
                            this.props.ToAddress.toLowerCase()
                          ].block >= 0 ||
                          this.props.localStorage.unreadMessage[
                            this.props.ToAddress.toLowerCase()
                          ].markUnread)
                        ? "fw-bold"
                        : null
                      : null
                  }
                >
                  <b>
                    {this.props.FromAddress.toLowerCase() ===
                    this.props.respont.address.toLowerCase() ? (
                      <>
                        <i className="bi bi-check2-all me-1"></i>
                        You:
                      </>
                    ) : null}
                  </b>{" "}
                  {this.props.MediaLink.length > 0 ? (
                    <i className="bi bi-image"></i>
                  ) : null}{" "}
                  {this.props.MessageText.error ? (
                    <>
                      <b className="text-danger fw-normal rounded-0 bg-transparent">
                        <i className="bi bi-info-circle-fill"></i> Error:{" "}
                      </b>{" "}
                      {this.props.MessageText.error} The appearance of this
                      message can be caused by misuse on external platforms.
                    </>
                  ) : (
                    this.props.MessageText
                  )}
                </p>
                {this.props.localStorage.unreadMessage ? (
                  this.props.localStorage.unreadMessage[
                    this.state.opponent.toLowerCase()
                  ] &&
                  this.props.localStorage.unreadMessage[
                    this.state.opponent.toLowerCase()
                  ].markUnread ? (
                    <span class="badge"> </span>
                  ) : null
                ) : null}
              </div>
            ) : null}
          </div>
        </div>
      </Link>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    respont: state.user.respont,
    interactions: state.user.interactions,
    localStorage: state.user.localStorage,
    pendingMessages: state.user.pendingMessages,
    opponent: state.user.opponent,
    profiles: state.user.profiles,
    connected: state.user.connected,
    socketId: state.user.socketId,
    room: state.user.room,
  };
};

const mapDispatchToProps = {
  setOpponent,
  setLocalStorage,
  setProfiles,
};

export default connect(mapStateToProps, mapDispatchToProps)(List);

import React from "react";
import { ethers } from "ethers";
import undrawAuth from "../asset/undraw-auth.svg";
import Cookies from "universal-cookie";
import { Link, withRouter } from "react-router-dom";

import { connect } from "react-redux";
import {
  setOpponent,
  setOpponents,
  setBlockedList,
  getLocalStorage,
  setLocalStorage,
} from "../redux/actions/getData";

import IntroChat from "../components/IntroChat";
import ModalScan from "../components/Scan";
import WaitingApprove from "../components/Waiting";
import ModalMnemonic from "../components/Mnemonic";
import ModalPassword from "../components/Password";

const cookies = new Cookies();

class HomeComponent extends React.Component {
  state = {
    connected: false,
    falseNetwork: false,
    accountAddress: "",
    deployingHash: "",
    balance: 0,
  };

  componentDidMount() {
    const destinationQuery = new URLSearchParams(window.location.search).get(
      "destination"
    );
    if (
      localStorage.getItem("mnemonic") &&
      (window.location.pathname === "/" ||
        window.location.pathname !== "/password")
    )
      window.location.href = `/password${
        window.location.pathname !== "/" ? `?destination=` : ""
      }${
        destinationQuery
          ? destinationQuery
          : window.location.href
              .split("/")
              .splice(3, window.location.href.split("/").length)
              .join("/")
      }`;

    if (window.location.pathname === "/password" && !cookies.get("token")) {
      localStorage.removeItem("mnemonic");
      this.redirect();
    }

    document.addEventListener("contextmenu", (e) => {
      e.preventDefault();
    });
    this.props.getLocalStorage("unreadMessage");
    this.props.getLocalStorage("data");

    try {
      if (
        ethers.utils.isAddress(this.props.match.params.address) &&
        this.props.match.params.address !== this.props.respont.address
      ) {
        this.props.setOpponent(this.props.match.params.address);
        document.title = `Respont Web3 App | ${this.props.match.params.address}`;
      } else {
        this.props.setOpponent("");
        document.title = `Respont Web3 App`;
      }
    } catch (e) {
      this.props.setOpponent("");
      document.title = `Respont Web3 App`;
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.scanning && !this.props.scanning) this.redirect();
    if (
      Object.keys(prevProps.interactions).length === 0 &&
      Object.keys(this.props.interactions).length > 0
    ) {
      this.redirect();
      this.handleConnect();
    }

    try {
      if (prevProps.match.params !== this.props.match.params) {
        if (
          ethers.utils.isAddress(this.props.match.params.address) &&
          this.props.match.params.address !== this.props.respont.address
        ) {
          this.props.setOpponent(this.props.match.params.address);
          document.title = `Respont Web3 App | ${this.props.match.params.address}`;
        } else {
          this.props.setOpponent("");
          document.title = `Respont Web3 App`;
        }
      }
    } catch (e) {
      this.props.setOpponent("");
      document.title = `Respont Web3 App`;
    }
  }

  redirect = () => {
    const { history } = this.props;

    const queryParse = new URLSearchParams(window.location.search);
    const destination = queryParse.get("destination");

    return history.push(`/${destination || ""}`);
  };

  handleConnect = async () => {
    this.setState({
      balance: parseInt(
        await this.props.respont._providers.storage.getBalance(
          this.props.respont.address
        ),
        10
      ),
    });

    this.setState({
      accountAddress: this.props.respont.address,
      connected: true,
    });
  };

  render() {
    return (
      <>
        {this.state.connected || (this.props.connected && this.props.room) ? (
          <IntroChat
            connections={this.props.connections}
            opponent={this.props.opponent}
            profilePage={this.props.profilePage}
            menu={this.props.match.params.menu}
            opponentProfile={this.props.opponentProfile}
            opponentMedia={this.props.opponentMedia}
            history={this.props.history}
          />
        ) : (
          <>
            <div className="connect-box anti-block">
              <div className="logo">
                <p className="logo-img">RESPONT</p>
                <div className="rounded shadow-sm">
                  <h5>Sign In</h5>
                  <img
                    src={undrawAuth}
                    alt="Connect Web3 Wallet"
                    onDragStart={(event) => event.preventDefault()}
                  />
                  <p>Sign in or scan session QR to continue.</p>
                  {!this.state.falseNetwork && !this.state.connected ? (
                    <>
                      <ModalScan
                        show={
                          this.props.match &&
                          this.props.match.params.address === "scan" &&
                          !this.props.scanning
                            ? true
                            : false
                        }
                      />
                      <WaitingApprove
                        show={
                          this.props.match &&
                          this.props.match.params.address === "scan" &&
                          this.props.scanning
                            ? true
                            : false
                        }
                      />{" "}
                      <ModalMnemonic
                        show={
                          this.props.match &&
                          (this.props.match.params.address === "generate" ||
                            this.props.match.params.address === "import")
                            ? true
                            : false
                        }
                        section={
                          this.props.match
                            ? this.props.match.params.address
                            : "generate"
                        }
                      />
                      <ModalPassword
                        show={
                          this.props.match &&
                          this.props.match.params.address === "password"
                        }
                      />
                      <Link
                        to={`${
                          localStorage.getItem("mnemonic")
                            ? "/password"
                            : "/generate"
                        }${
                          window.location.pathname
                            .split("/")
                            .filter((path) => path.length > 0).length > 0
                            ? `?destination=${window.location.href
                                .split("/")
                                .splice(
                                  3,
                                  window.location.href.split("/").length
                                )
                                .join("/")}`
                            : ""
                        }`}
                      >
                        <button
                          className="rounded mt-2 mx-2"
                          disabled={this.props.connected ? true : false}
                        >
                          Sign In
                        </button>
                      </Link>
                      {/* <Link
                        to={`/scan${
                          window.location.pathname
                            .split("/")
                            .filter((path) => path.length > 0).length > 0
                            ? `?destination=${window.location.href
                                .split("/")
                                .splice(
                                  3,
                                  window.location.href.split("/").length
                                )
                                .join("/")}`
                            : ""
                        }`}
                      >
                        <button className="rounded mt-2 mx-2">Scan QR</button>
                      </Link> */}
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    respont: state.user.respont,
    interactions: state.user.interactions,
    opponent: state.user.opponent,
    opponents: state.user.opponents,
    signer: state.user.signer,
    localStorage: state.user.localStorage,
    connected: state.user.connected,
    room: state.user.room,
    scanning: state.user.scanning,
  };
};

const mapDispatchToProps = {
  setOpponent,
  setOpponents,
  setBlockedList,
  getLocalStorage,
  setLocalStorage,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(HomeComponent));

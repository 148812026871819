import React from "react";
import { connect } from "react-redux";
import { Connect, Interactions } from "@respont/app";

import { chain } from "../../constants/API";

class Blocked extends React.Component {
  unblock = async (address) => {
    try {
      await this.props.interactions.RemoveBlockList(address);
    } catch (e) {
      console.log(e);

      const respont = await new Connect(
        this.props.respont.mnemonic()
          ? this.props.respont.mnemonic()
          : this.props.respont.privateKey(),
        chain.rpcUrls[0]
      );
      this.props.setRespont(respont);

      const interact = new Interactions(respont, true);
      this.props.setInteractions(interact);

      this.unblock();
    }
  };

  render() {
    return (
      <div className="blocked">
        {this.props.blocked.length > 0 ? (
          this.props.blocked.map((address) => {
            return (
              <div className="d-flex">
                <div className="rounded bg-light text-dark">
                  <i className="bi bi-person-bounding-box"></i>
                </div>
                <p title={address}>{address}</p>
                <i
                  className="bi bi-x-lg text-danger"
                  title="Unblock"
                  onClick={() => this.unblock(address)}
                ></i>
              </div>
            );
          })
        ) : (
          <p className="text-center mt-5 mb-5 text-muted">
            You don't have blocked address list.
          </p>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    interactions: state.user.interactions,
    blocked: state.user.blocked,
  };
};

export default connect(mapStateToProps)(Blocked);

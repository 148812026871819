import React from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setBlockedList, setSelectMedia } from "../redux/actions/getData";
import { v4 as uuidv4 } from "uuid";
import { Connect, Interactions } from "@respont/app";

import { chain } from "../constants/API";
import avatarBroken from "../asset/avatar-broken.jpg";

import Modal from "react-bootstrap/Modal";

class ModalProfile extends React.Component {
  state = {
    pendingCreateKey: false,
  };

  block = async () => {
    await this.props.interactions.AddBlockList(this.props.opponent);
  };

  unblock = async () => {
    await this.props.interactions.RemoveBlockList(this.props.opponent);
  };

  redirect = () => {
    const { history } = this.props;

    return history.push(`/${this.props.opponent}`);
  };

  selectMedia = () => {
    let messages = this.props.messages[this.props.opponent.toLowerCase()];

    if (messages && messages.length > 0) {
      let selectMedia = [];
      messages.forEach((message, i) => {
        let MediaLinks = message.MediaLink;
        if (typeof message.MediaLink === "string") {
          MediaLinks = JSON.parse(message.MediaLink);
        }
        MediaLinks.forEach((mediaLink, j) => {
          selectMedia = [i, j];
        });
      });

      this.props.setSelectMedia(selectMedia);
    }
  };

  createKey = async () => {
    try {
      this.setState({
        pendingCreateKey: true,
      });
      const transaction = await this.props.interactions.GenerateKeyLocation(
        uuidv4(),
        this.props.opponent.toLowerCase()
      );

      await transaction.wait();
      this.setState({
        pendingCreateKey: false,
      });
    } catch (e) {
      console.log(e);

      const respont = await new Connect(
        this.props.respont.mnemonic()
          ? this.props.respont.mnemonic()
          : this.props.respont.privateKey(),
        chain.rpcUrls[0]
      );
      this.props.setRespont(respont);

      const interact = new Interactions(respont, true);
      this.props.setInteractions(interact);

      this.createKey();
    }
  };

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.redirect}
        contentClassName="rounded-circle"
        centered
      >
        <div className="modal-profile">
          <div className="profile">
            <img
              className="rounded-circle d-block"
              src={this.props.picture ? this.props.picture : avatarBroken}
              alt={this.props.opponent}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = avatarBroken;
              }}
              onDragStart={(event) => event.preventDefault()}
            />
            <div>
              <a
                href={`${chain.blockExplorerUrls}/address/${this.props.opponent}`}
                target="_blank"
                rel="noopener noreferrer"
                title={this.props.opponent}
              >
                {this.props.opponent}
              </a>
            </div>
          </div>
          <div className="list">
            <a
              href={`${chain.blockExplorerUrls}/address/${this.props.opponent}`}
              target="_blank"
              rel="noopener noreferrer"
              className="d-flex rounded"
            >
              <div className="purple rounded">
                <i className="bi bi-binoculars-fill"></i>
              </div>
              <p>Open Block Explorer</p>
            </a>
            <Link
              to={
                this.props.messages[this.props.opponent.toLowerCase()] &&
                this.props.messages[this.props.opponent.toLowerCase()].filter(
                  (message) => message.MediaLink.length > 0
                ).length > 0
                  ? `/${this.props.opponent}/media`
                  : "#"
              }
              className="d-flex rounded"
              onClick={this.selectMedia}
            >
              <div className="bg-warning text-dark rounded">
                <i className="bi bi-film"></i>
              </div>
              <p>
                Media{" "}
                {this.props.messages[this.props.opponent.toLowerCase()] &&
                this.props.messages[this.props.opponent.toLowerCase()].filter(
                  (message) => message.MediaLink.length > 0
                ).length > 0 ? null : (
                  <span className="badge bg-info text-dark">0 Media</span>
                )}
              </p>
            </Link>
            <div
              to="#"
              className="d-flex rounded"
              onClick={!this.state.pendingCreateKey ? this.createKey : null}
            >
              <div className="bg-primary rounded">
                <i className="bi bi-key-fill"></i>
              </div>
              <p
                className={
                  !this.state.pendingCreateKey ? "text-light" : "text-muted"
                }
              >
                Create New Key
              </p>
            </div>
            {this.props.blocked.includes(this.props.opponent) ? (
              <div className="d-flex rounded" onClick={this.unblock}>
                <div className="bg-success rounded">
                  <i className="bi bi-check-circle-fill"></i>
                </div>
                <p>Un-block</p>
              </div>
            ) : (
              <div className="d-flex rounded" onClick={this.block}>
                <div className="bg-danger rounded">
                  <i className="bi bi-x-circle-fill"></i>
                </div>
                <p>Block</p>
              </div>
            )}
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    respont: state.user.respont,
    interactions: state.user.interactions,
    selectedMedia: state.user.selectedMedia,
    messages: state.user.messages,
    blocked: state.user.blocked,
  };
};

const mapDispatchToProps = {
  setBlockedList,
  setSelectMedia,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ModalProfile));

import React from "react";
import { connect } from "react-redux";
import moment from "moment";

import { setSelectMedia, setMessage } from "../redux/actions/getData";

import imageBroken from "../asset/image-broken.jpg";

class Bubble extends React.Component {
  state = {
    MediaLink: [],
  };

  componentDidMount() {
    this.setState({
      MediaLink:
        typeof this.props.MediaLink === "string"
          ? JSON.parse(this.props.MediaLink)
          : this.props.MediaLink,
    });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.FromAddress !== this.props.FromAddress ||
      prevProps.ToAddress !== this.props.ToAddress ||
      prevProps.MessageText !== this.props.MessageText ||
      prevProps.MediaLink !== this.props.MediaLink ||
      prevProps.MediaLink !== this.props.MediaLink
    ) {
      this.setState({
        MediaLink:
          typeof this.props.MediaLink === "string"
            ? JSON.parse(this.props.MediaLink)
            : this.props.MediaLink,
      });
    }
  }

  openImage = (i) => {
    this.props.setSelectMedia([this.props.messageIndex, i]);

    const { history } = this.props;

    history.push(`/${this.props.opponent}/media`);
  };

  render() {
    return (
      <div
        className={`${
          this.props.FromAddress === this.props.respont.address
            ? "you"
            : "opponent"
        }`}
      >
        {this.state.MediaLink.length > 0 ? (
          <div className="message mb-1">
            <div className="image">
              <div>
                {this.state.MediaLink.map((image, i) => {
                  return i >= 0 && i < 3 ? (
                    <img
                      src={image}
                      alt={this.props.opponent}
                      className="rounded"
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = imageBroken;
                      }}
                      onClick={() => this.openImage(i)}
                      onDragStart={(event) => event.preventDefault()}
                    />
                  ) : null;
                })}
                {this.state.MediaLink.length === 4 ? (
                  <img
                    src={this.state.MediaLink[3]}
                    alt={this.props.opponent}
                    className="rounded"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = imageBroken;
                    }}
                    onClick={() => this.openImage(3)}
                    onDragStart={(event) => event.preventDefault()}
                  />
                ) : null}
                {this.state.MediaLink.length > 4 ? (
                  <div className="more-image" onClick={() => this.openImage(3)}>
                    <div className="rounded">
                      <p>+{this.state.MediaLink.length - 3}</p>
                    </div>
                    <img
                      src={this.state.MediaLink[3]}
                      alt={this.props.opponent}
                      className="rounded"
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = imageBroken;
                      }}
                      onDragStart={(event) => event.preventDefault()}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        ) : null}
        {this.props.MessageText.length > 0 ? (
          <div className="message">
            {this.props.isError ? (
              <i className="bi bi-exclamation-triangle text-light me-2 mt-2"></i>
            ) : null}
            <div className="text">
              <p className="mx-1">
                {this.props.MessageText.error ? (
                  <>
                    <i className="bi bi-info-circle-fill text-danger opacity-75"></i>{" "}
                    <span className="text-danger">Error:</span>{" "}
                    <span className="text-secondary">
                      {this.props.MessageText.error} The appearance of this
                      message can be caused by misuse on external platforms.
                    </span>
                  </>
                ) : (
                  this.props.MessageText
                )}
              </p>
            </div>
          </div>
        ) : null}
        <p className="time mt-1">
          {!this.props.isError
            ? moment.unix(this.props.MessageTimestamp).format("hh:mma")
            : null}
          {this.props.FromAddress === this.props.respont.address ? (
            this.props.isPending && !this.props.isError ? (
              <i className="bi bi-clock ms-2 me-1"></i>
            ) : !this.props.isError && !this.props.isError ? (
              <i className="bi bi-check2-all ms-2 me-1"></i>
            ) : !this.props.isPending && this.props.isError ? (
              <>
                {this.props.isError}
                <i className="bi bi-exclamation-triangle ms-2 me-1"></i>
              </>
            ) : null
          ) : null}
        </p>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    respont: state.user.respont,
    messages: state.user.messages,
    opponent: state.user.opponent,
    interactions: state.user.interactions,
  };
};

const mapDispatchToProps = {
  setSelectMedia,
  setMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(Bubble);

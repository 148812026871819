export const setProfile = (profile) => {
  return (dispatch) => {
    dispatch({
      type: "SET_PROFILE",
      data: profile,
    });
  };
};

export const setProfiles = (address, profile) => {
  return (dispatch) => {
    dispatch({
      type: "SET_PROFILES",
      data: {
        address,
        profile,
      },
    });
  };
};

export const setOpponent = (address) => {
  return (dispatch) => {
    dispatch({
      type: "GET_STARTING_CHAT",
      data: address,
    });
  };
};

export const setOpponents = (opponents) => {
  opponents = opponents.sort((a, b) => b.MessageTimestamp - a.MessageTimestamp);

  return (dispatch) => {
    dispatch({
      type: "STORE_MESSAGE",
      data: opponents,
    });
  };
};

export const setBlockedList = (list) => {
  return (dispatch) => {
    dispatch({
      type: "SET_BLOCKED_LIST",
      data: list,
    });
  };
};

export const autoUpdater = (data) => {
  return (dispatch) => {
    dispatch({
      type: "AUTO_UPDATER",
      data: data,
    });
  };
};

export const setLocalStorage = (name, data) => {
  localStorage.setItem(name, JSON.stringify(data));

  return (dispatch) => {
    dispatch({
      type: "SET_LOCAL_STORAGE",
      data: {
        name,
        data,
      },
    });
  };
};

export const getLocalStorage = (name) => {
  return (dispatch) => {
    dispatch({
      type: "GET_LOCAL_STORAGE",
      data: {
        name,
        data: JSON.parse(localStorage.getItem(name)),
      },
    });
  };
};

export const setSocketId = (id) => {
  return (dispatch) => {
    dispatch({
      type: "SET_SOCKET_ID",
      id,
    });
  };
};

export const setConnection = (connections) => {
  return (dispatch) => {
    dispatch({
      type: "SET_CONNECTIONS",
      data: connections,
    });
  };
};

export const setMessage = (address, message) => {
  return (dispatch) => {
    dispatch({
      type: "SET_MESSAGE",
      data: {
        address: address.toLowerCase(),
        message,
      },
    });
  };
};

export const setMessaging = (message) => {
  return (dispatch) => {
    dispatch({
      type: "SET_MESSAGING",
      data: message,
    });
  };
};

export const setSelectMedia = (media) => {
  return (dispatch) => {
    dispatch({
      type: "SET_SELECT_MEDIA",
      data: media,
    });
  };
};

export const setHasKey = (address, hasKey) => {
  return (dispatch) => {
    dispatch({
      type: "SET_HAS_KEY",
      data: {
        address,
        hasKey,
      },
    });
  };
};

export const setRespont = (respont) => {
  return (dispatch) => {
    dispatch({
      type: "SET_RESPONT",
      data: respont,
    });
  };
};

export const setInteractions = (interactions) => {
  return (dispatch) => {
    dispatch({
      type: "SET_INTERACTIONS",
      data: interactions,
    });
  };
};

export const setListen = (listen) => {
  return (dispatch) => {
    dispatch({
      type: "SET_LISTEN",
      data: listen,
    });
  };
};

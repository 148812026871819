import React from "react";

class Mnemonic extends React.Component {
  render() {
    return (
      <div className="mnemonic">
        <div className="p-3 bg-warning anti-block rounded text-dark mb-4 font-weight-bold">
          Please keep your mnemonics or private key secret! Do not share with
          anyone
        </div>
        <div className="row">
          {this.props.respont.mnemonic()
            ? this.props.respont
                .mnemonic()
                .split(" ")
                .map((mnemonic) => {
                  return (
                    <div className="col-6">
                      <input className="rounded" value={mnemonic} disabled />
                    </div>
                  );
                })
            : null}
        </div>
        <p
          className="text-center cursor-pointer mt-3"
          onClick={(e) =>
            navigator.clipboard.writeText(
              this.props.respont.mnemonic()
                ? this.props.respont.mnemonic()
                : this.props.respont.privateKey()
            )
          }
        >
          <i className="bi bi-clipboard"></i> Click here to copy
        </p>
      </div>
    );
  }
}

export default Mnemonic;

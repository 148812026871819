import React from "react";
import { connect } from "react-redux";
import Axios from "axios";
import CryptoJS from "crypto-js";
import Cookies from "universal-cookie";
import { Connect, Interactions, Listen } from "@respont/app";

import Modal from "react-bootstrap/Modal";

import { API, chain } from "../constants/API";
import {
  setRespont,
  setInteractions,
  setListen,
} from "../redux/actions/getData";

const cookies = new Cookies();

class ModalPassword extends React.Component {
  state = {
    password: "",
    errorMessage: "",
    isWaiting: false,
  };

  handleCheckPassword = (e) => {
    e.preventDefault();
    try {
      const token = cookies.get("token");

      const decryptedToken = CryptoJS.AES.decrypt(
        token,
        this.state.password
      ).toString(CryptoJS.enc.Utf8);

      this.setState({ errorMessage: "", isWaiting: true });

      this.verifyToken(decryptedToken);
    } catch (e) {
      this.setState({ errorMessage: "Wrong password!", isWaiting: false });
    }
  };

  verifyToken = async (decryptedToken) => {
    try {
      const verify = await Axios.patch(`${API}/key/verify`, null, {
        headers: {
          Authorization: `Bearer ${decryptedToken}`,
        },
      });

      let mnemonic = "";
      verify.data.data.forEach((c) => (mnemonic += String.fromCharCode(c)));

      const respont = await new Connect(atob(mnemonic), chain.rpcUrls[0]);
      this.props.setRespont(respont);

      const interact = new Interactions(respont, true);
      this.props.setInteractions(interact);

      const listen = new Listen(respont);
      this.props.setListen(listen);

      this.setState({ errorMessage: "", password: "", isWaiting: false });
    } catch (e) {
      console.log(e);
      this.setState({
        errorMessage: "Error while verify mnemonic. Please try again later.",
      });
    }
  };

  render() {
    return (
      <Modal show={this.props.show} contentClassName="rounded-circle" centered>
        <div className="modal-mnemonic text-light">
          <div>
            <form onSubmit={this.handleCheckPassword}>
              <div className="mt-3">
                <h5 className="mb-3">Password</h5>
                {this.state.errorMessage ? (
                  <div className="alert bg-danger">
                    <i className="bi bi-x-square-fill"></i>
                    <span className="text-light">
                      {this.state.errorMessage}
                    </span>
                  </div>
                ) : null}
                <p className="mb-2">Password</p>
                <input
                  className="rounded"
                  type="password"
                  placeholder="Password"
                  onChange={(e) =>
                    this.setState({
                      password: e.target.value,
                    })
                  }
                  value={this.state.password}
                />
                <button
                  className={`mt-3 mx-1 rounded ${
                    this.state.isWaiting ? "text-secondary" : null
                  }`}
                  disabled={this.state.isWaiting}
                >
                  {this.state.isWaiting ? "Loading" : "Submit"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapDispatchToProps = {
  setRespont,
  setInteractions,
  setListen,
};

export default connect(null, mapDispatchToProps)(ModalPassword);

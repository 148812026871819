import { ethers } from "ethers";
import io from "socket.io-client";

import { reactStore as store } from "../..";
import { API } from "../../constants/API";
import * as Socket from "../actions/Socket";
import Cookies from "universal-cookie";

const cookies = new Cookies();
let socket;

export const init = () => {
  if (!socket) {
    socket = io.connect(API);
    store.dispatch({ type: "SET_CONNECTING", data: true });
  }
  if (
    !store.getState().user.connected &&
    !store.getState().user.connecting &&
    socket
  )
    socket = io.connect(API);

  socket.on("connect", () => {
    store.dispatch({ type: "SET_SOCKET_ID", id: socket.id });
  });

  socket.on("disconnect", () => {
    store.dispatch({ type: "SET_SOCKET_ID", id: null });
    store.dispatch({ type: "SET_CONNECTIONS", data: [] });
    store.dispatch({ type: "SET_ROOM", data: "" });
    store.dispatch({ type: "SET_SCANNING", data: false });
  });
};

export const subscribe = (socketId, address) => {
  if (!socket) return false;

  if (!socketId) {
    if (socket._callbacks.$subscribe) return true;

    socket.on("subscribe", (data) => {
      if (typeof data !== "object") return false;
      if (!data.socketId || !data.ipAddress) return false;
      if (
        store
          .getState()
          .user.connection.some((conn) => conn.socketId === data.socketId)
      )
        return false;

      const newConnection = [
        ...store.getState().user.connection,
        { ...data, connected: false },
      ];

      store.dispatch({ type: "SET_CONNECTIONS", data: newConnection });
    });
  } else {
    if (socket._callbacks.$subscribe) return true;

    store.dispatch({ type: "SET_SCANNING", data: true });

    socket.emit("subscribe", {
      socketId,
      address,
    });

    socket.on("accepted", (data) => {
      if (typeof data !== "object") return false;
      if (!data.socketId) return false;

      const newConnection = [
        ...store.getState().user.connection,
        { ...data, connected: true },
      ];

      store.dispatch({ type: "SET_CONNECTIONS", data: newConnection });
      store.dispatch({ type: "SET_ROOM", data: data.socketId });
      store.dispatch({ type: "SET_SCANNING", data: false });

      Socket.sync();
      Socket.profile();
      Socket.profiles();
      Socket.chats();
      Socket.chat();
      Socket.sendingMessage();
    });

    socket.on("rejected", (data) => {
      store.dispatch({ type: "SET_SCANNING", data: true });
    });
  }
};

export const newSubscriber = () => {
  if (!socket) return false;
  if (socket._callbacks.$newSubscribe) return true;

  socket.on("newSubscriber", (data) => {
    if (typeof data !== "object") return false;
    if (!data.socketId || !data.address || !data.ipAddress) return false;
    if (data.address !== store.getState().user.respont.address) return false;
    if (
      store
        .getState()
        .user.connection.some((conn) => conn.socketId === data.socketId)
    )
      return false;

    const newConnection = [
      ...store.getState().user.connection,
      { ...data, connected: true },
    ];

    store.dispatch({ type: "SET_CONNECTIONS", data: newConnection });
  });
};

export const accept = (socketId) => {
  if (!socket) return false;

  const index = store
    .getState()
    .user.connection.findIndex((conn) => conn.socketId === socketId);

  const newConnection = [...store.getState().user.connection];
  newConnection[index].connected = true;

  store.dispatch({ type: "SET_CONNECTIONS", data: newConnection });
  store.dispatch({ type: "SET_ROOM", data: socket.id });

  socket.emit("accept", { socketId });

  if (store.getState().user.opponents.length < 1) return true;
  Socket.chats(store.getState().user.opponents);

  return true;
};

export const reject = (socketId) => {
  if (!socket) return false;

  store.dispatch({
    type: "SET_CONNECTIONS",
    data: store
      .getState()
      .user.connection.filter((conn) => conn.socketId !== socketId),
  });

  socket.emit("reject", { socketId });

  return true;
};

export const sync = (storageName, storageContent) => {
  if (!socket) return false;

  if (storageName && storageContent) {
    socket.emit("sync", {
      destination: store.getState().user.room,
      storageName,
      storageContent,
    });

    return true;
  }

  if (socket._callbacks.$sync) return true;

  socket.on("sync", (data) => {
    if (typeof data !== "object") return false;
    if (!data.storageName || !data.storageContent) return false;
    if (
      store.getState().user.localStorage[data.storageName] ===
      data.storageContent
    )
      return false;

    store.dispatch({
      type: "SET_LOCAL_STORAGE",
      data: {
        name: data.storageName,
        data: data.storageContent,
      },
    });
    localStorage.setItem(data.storageName, JSON.stringify(data.storageContent));

    store.dispatch({
      type: "SET_LOCAL_STORAGE",
      data: {
        name: storageName,
        data: storageContent,
      },
    });
  });

  return true;
};

export const profile = (profile) => {
  if (!socket) return false;

  if (!profile)
    socket.emit("profile", { destination: store.getState().user.room });
  else socket.emit("profile", { profile });

  if (socket._callbacks.$profile) return true;

  socket.on("profile", (data) => {
    if (!data)
      return socket.emit("profile", { profile: store.getState().user.profile });
    if (store.getState().user.room === store.getState().user.socketId)
      return true;

    store.dispatch({
      type: "SET_PROFILE",
      data: data.profile,
    });
  });
};

export const profiles = (profiles) => {
  if (!socket) return false;

  if (!profiles)
    socket.emit("profiles", { destination: store.getState().user.room });
  else socket.emit("profiles", { profiles });

  if (socket._callbacks.$profiles) return true;

  socket.on("profiles", (data) => {
    if (!data)
      return socket.emit("profiles", {
        profiles: store.getState().user.profiles,
      });
    if (store.getState().user.room === store.getState().user.socketId)
      return true;

    Object.keys(data.profiles).forEach((address) => {
      store.dispatch({
        type: "SET_PROFILES",
        data: {
          address: address,
          profile: data.profiles[address],
        },
      });
    });
  });
};

export const chats = (chats) => {
  if (!socket) return false;

  if (!chats)
    socket.emit("messages", { destination: store.getState().user.room });
  if (chats && chats.length >= 0) socket.emit("messages", chats);

  if (socket._callbacks.$chats) return true;

  socket.on("messages", (data) => {
    if (!data) return socket.emit("messages", store.getState().user.opponents);
    if (store.getState().user.room === store.getState().user.socketId)
      return true;
    if (typeof data !== "object" && chats.length <= 0) return false;

    store.dispatch({
      type: "STORE_MESSAGE",
      data,
    });
  });
};

export const chat = (address) => {
  if (!socket) return false;

  if (address && ethers.utils.isAddress(address))
    socket.emit("message", {
      address,
      destination: store.getState().user.room,
    });

  if (socket._callbacks.$chat) return false;

  socket.on("message", (data) => {
    if (data.address && data.destination && !data.chat) {
      store
        .getState()
        .user.interactuibs.Message(data.address)
        .then((chat) =>
          socket.emit("message", {
            destination: data.destination,
            address: data.address,
            chat,
          })
        );
    }
    if (store.getState().user.room === store.getState().user.socketId)
      return true;
    if (typeof data !== "object") return false;

    store.dispatch({
      type: "SET_MESSAGE",
      data: {
        address: data.address,
        message: data.chat,
      },
    });
  });
};

export const sendingMessage = (message, mediaLink, opponent, hash) => {
  if (!socket) return false;

  if (message && mediaLink && opponent && !hash)
    return socket.emit("send", {
      destination: store.getState().user.room,
      message,
      mediaLink,
      opponent,
    });

  if (!message && !mediaLink && opponent && hash)
    return socket.emit("send", {
      opponent,
      hash,
    });

  if (opponent && !ethers.utils.isAddress(opponent)) return false;

  if (socket._callbacks.$sendingMessage) return false;

  socket.on("send", (data) => {
    if (typeof data !== "object") return false;

    if (data.message && data.mediaLink && data.opponent && data.destination) {
      store
        .getState()
        .user.interactions.SendMessage(data.opponent, data.message, data.mediaLink)
        .then((sent) => {
          socket.emit("send", {
            destination: data.destination,
            hash: sent.hash,
          });
        });
    } else if (
      !data.message &&
      !data.mediaLink &&
      data.hash &&
      data.destination
    ) {
      let getCookies = [];
      if (cookies.get("confirming")) getCookies = cookies.get("confirming");
      getCookies.push(data.hash);
      cookies.set("confirming", getCookies, { path: "/" });

      store.dispatch({ type: "SET_MESSAGING", data: "" });
    }
  });
};

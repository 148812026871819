import React from "react";
import { withRouter } from "react-router-dom";

import Modal from "react-bootstrap/Modal";

import { init, subscribe } from "../redux/actions/Socket";
import { QrReader } from "react-qr-reader";

class ModalScan extends React.Component {
  redirect = () => {
    const { history } = this.props;

    const queryParse = new URLSearchParams(window.location.search);
    const destination = queryParse.get("destination");

    return history.push(`/${destination ? destination : ""}`);
  };

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.redirect}
        contentClassName="rounded-circle"
        centered
      >
        <div className="modal-scan text-light">
          <QrReader
            constraints={{
              facingMode: "environment",
            }}
            scanDelay={500}
            onResult={(result) => {
              if (result) {
                init();
                subscribe(
                  JSON.parse(result.text).socketId,
                  JSON.parse(result.text).address
                );
              }
            }}
            facingMode="user"
          />
        </div>
      </Modal>
    );
  }
}

export default withRouter(ModalScan);

const init_state = {
  profile: "",
  profiles: {},
  opponent: "",
  opponents: [],
  address: "",
  updater: {},
  localStorage: {},
  socketId: "",
  connection: [],
  room: "",
  scanning: false,
  connected: false,
  connecting: false,
  messages: {},
  message: "",
  selectedMedia: [-1, -1],
  hasKey: {},
  blocked: [],
  contract: {},
  provider: {},
  respont: {},
  interactions: {},
  listen: {},
};

const reducer = (state = init_state, action) => {
  switch (action.type) {
    case "SET_PROFILE":
      return { ...state, profile: action.data };
    case "SET_PROFILES":
      let profiles = { ...state.profiles };
      profiles[action.data.address] = action.data.profile;
      return { ...state, profiles: profiles };
    case "GET_STARTING_CHAT":
      return { ...state, opponent: action.data };
    case "STORE_MESSAGE":
      return { ...state, opponents: action.data };
    case "STORE_ADDRESS":
      return { ...state, address: action.data };
    case "AUTO_UPDATER":
      return { ...state, updater: action.data };
    case "SET_LOCAL_STORAGE":
      let setLocalStorage = { ...state.localStorage };
      setLocalStorage[action.data.name] = action.data.data;
      return { ...state, localStorage: setLocalStorage };
    case "GET_LOCAL_STORAGE":
      var getLocalStorage = { ...state.localStorage };
      getLocalStorage[action.data.name] = action.data.data;
      return { ...state, localStorage: getLocalStorage };
    case "SET_SOCKET_ID":
      return {
        ...state,
        socketId: action.id,
        connecting: false,
        connected: action.id === null ? false : true,
        connection: action.id === null ? [] : state.connection,
      };
    case "SET_CONNECTIONS":
      return { ...state, connection: action.data };
    case "SET_CONNECTING":
      return { ...state, connecting: action.data };
    case "SET_ROOM":
      return { ...state, room: action.data };
    case "SET_SCANNING":
      return { ...state, scanning: action.data };
    case "SET_MESSAGE":
      return {
        ...state,
        messages: {
          ...state.messages,
          [action.data.address]: action.data.message,
        },
      };
    case "SET_SELECT_MEDIA":
      return { ...state, selectedMedia: action.data };
    case "SET_MESSAGING":
      return { ...state, message: action.data };
    case "SET_HAS_KEY":
      return {
        ...state,
        hasKey: {
          ...state.hasKey,
          [action.data.address]: action.data.hasKey,
        },
      };
    case "SET_BLOCKED_LIST":
      return { ...state, blocked: action.data };
    case "SET_RESPONT":
      return { ...state, respont: action.data };
    case "SET_INTERACTIONS":
      return { ...state, interactions: action.data };
    case "SET_LISTEN":
      return { ...state, listen: action.data };
    default:
      return state;
  }
};

export const data = init_state;
export default reducer;

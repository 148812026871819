import React from "react";
import { connect } from "react-redux";
import {
  setOpponent,
  setLocalStorage,
  setMessage,
  setMessaging,
  setHasKey,
  setRespont,
  setInteractions,
} from "../redux/actions/getData";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import Axios from "axios";
import { Link } from "react-router-dom";
import { ethers } from "ethers";
import { v4 as uuidv4 } from "uuid";
import { Connect, Interactions } from "@respont/app";
import Compressor from "compressorjs";

import Image from "./Image";
import ModalProfile from "./Profile";
import Bubble from "./Bubble";

import { sync, chat, sendingMessage } from "../redux/actions/Socket";
import { API, chain } from "../constants/API";
import avatarBroken from "../asset/avatar-broken.jpg";

const refBubble = React.createRef();
const refLoading = React.createRef();

class Dialog extends React.Component {
  state = {
    files: [],
    preview: [],
    showImage: [],
    showingImage: "",
    unreadCount: 0,
    unreadBlock: -1,
    scrollPosition: undefined,
    isFetching: true,
    isZero: false,
    pendingCreateKey: false,
  };

  async componentDidMount() {
    this.scrollListener();

    if (!this.props.hasKey[this.props.opponent.toLowerCase()])
      await this.checkKey();

    if (
      this.props.opponent.toLowerCase() ===
      this.props.respont.address.toLowerCase()
    ) {
      const { history } = this.props;

      return history.push("/");
    }

    if (!this.props.messages[this.props.opponent.toLowerCase()])
      await this.fetchMessage();

    this.handleRead();
  }

  async componentDidUpdate(prevProps) {
    if (
      prevProps.selectedMedia !== this.props.selectedMedia &&
      this.props.selectedMedia[0] >= 0 &&
      document.getElementsByClassName("bubble-list").length > 0
    )
      document
        .getElementsByClassName("bubble-list")
        [this.props.selectedMedia[0]].scrollIntoView({
          behavior: "smooth",
        });

    if (this.state.isFetching && refLoading.current) {
      const bubbleElement = refBubble.current;

      if (bubbleElement.scrollTop <= 31)
        bubbleElement.scrollTo({
          behavior: "smooth",
          top: 32,
        });
    }

    if (
      !this.state.isFetching &&
      !this.state.isZero &&
      this.state.scrollPosition < 30 &&
      this.props.messages[this.props.opponent.toLowerCase()] &&
      this.props.messages[this.props.opponent.toLowerCase()].length > 0 &&
      refBubble.current
    ) {
      const element = refBubble.current;
      const currentHeight = element.scrollHeight;
      const currentPosition = element.scrollTop;

      await this.fetchMessage(
        this.props.messages[this.props.opponent.toLowerCase()][0].BlockHeight -
          1
      );

      const newHeight = element.scrollHeight;

      const loadingBottomPosition = 20;
      const scrollToPosition =
        currentPosition - loadingBottomPosition + (newHeight - currentHeight);

      element.scrollTo({
        top: scrollToPosition,
      });
    }

    if (
      this.props.opponent.toLowerCase() ===
      this.props.respont.address.toLowerCase()
    ) {
      const { history } = this.props;

      return history.push("/");
    }

    if (prevProps.opponent !== this.props.opponent && this.props.opponent) {
      this.setState({
        isZero: false,
      });
      this.scrollListener();

      if (!this.props.hasKey[this.props.opponent.toLowerCase()])
        await this.checkKey();

      if (!this.props.messages[this.props.opponent.toLowerCase()])
        await this.fetchMessage();

      this.setState({
        hash: "",
        files: [],
        preview: [],
        uploading: false,
        unreadCount: 0,
        unreadBlock: -1,
      });

      this.handleRead();
    }

    if (
      !prevProps.messages[this.props.opponent.toLowerCase()] &&
      this.props.messages[this.props.opponent.toLowerCase()]
    ) {
      this.scrollListener();
    }
  }

  componentWillUnmount() {
    const element = refBubble.current;
    if (element) {
      element.removeEventListener("scroll", () => {
        this.setState({
          scrollPosition:
            element.scrollTop !== 0
              ? element.scrollTop
              : this.state.scrollPosition,
        });
      });
    }
  }

  scrollListener = () => {
    const element = refBubble.current;
    if (element) {
      element.scrollTop = element.scrollHeight;
      this.setState({ scrollPosition: element.scrollTop });
      if (!element.getAttribute("listener")) {
        element.addEventListener("scroll", () => {
          this.setState({
            scrollPosition:
              element.scrollTop !== 0
                ? element.scrollTop
                : this.state.scrollPosition,
          });
        });
      }

      setTimeout(() => {
        element.scrollTop = element.scrollHeight;
      }, 0);
    }
  };

  handleScrollToBottom = () => {
    let element = refBubble.current;
    element.scrollTo({
      behavior: "smooth",
      top: element.scrollHeight,
    });
  };

  handleRead = () => {
    if (this.props.localStorage.unreadMessage)
      if (
        this.props.localStorage.unreadMessage[this.props.opponent.toLowerCase()]
      ) {
        const unreadJSON = this.props.localStorage.unreadMessage;
        this.setState({
          unreadBlock: unreadJSON[this.props.opponent.toLowerCase()].block,
        });
        delete unreadJSON[this.props.opponent.toLowerCase()];

        this.props.setLocalStorage("unreadMessage", unreadJSON);

        sync("unreadMessage", unreadJSON);
      }
  };

  chatInputHandler = (e) => {
    this.props.setMessaging(e.target.value);
  };

  checkKey = async () => {
    try {
      this.setState({
        isFetching: true,
        isChecking: true,
      });

      const hasKey = await this.props.interactions.GetKeyLocation(
        this.props.opponent
      );
      this.props.setHasKey(this.props.opponent.toLowerCase(), hasKey);

      this.setState({ isFetching: false, isChecking: false });
    } catch (e) {
      console.log(e);

      const respont = await new Connect(
        this.props.respont.mnemonic()
          ? this.props.respont.mnemonic()
          : this.props.respont.privateKey(),
        chain.rpcUrls[0]
      );
      this.props.setRespont(respont);

      const interact = new Interactions(respont, true);
      this.props.setInteractions(interact);

      this.checkKey();
    }
  };

  createKey = async () => {
    try {
      this.setState({
        pendingCreateKey: true,
      });

      const transaction = await this.props.interactions.GenerateKeyLocation(
        uuidv4(),
        this.props.opponent.toLowerCase()
      );

      await transaction.wait();
      this.props.setHasKey(this.props.opponent.toLowerCase());

      this.setState({
        pendingCreateKey: false,
      });
    } catch (e) {
      console.log(e);

      const respont = await new Connect(
        this.props.respont.mnemonic()
          ? this.props.respont.mnemonic()
          : this.props.respont.privateKey(),
        chain.rpcUrls[0]
      );
      this.props.setRespont(respont);

      const interact = new Interactions(respont, true);
      this.props.setInteractions(interact);
    }
  };

  handleDeleteImage = (i) => {
    let files = this.state.files;
    let preview = this.state.preview;

    files.splice(i, 1);
    preview.splice(i, 1);

    this.setState({ files, preview });
  };

  sendingMessage = async () => {
    const message = this.props.message;
    const files = this.state.files;
    const preview = this.state.preview;
    this.props.setMessaging("");

    let pendingMessages = [];
    let pendingMessage = {
      FromAddress: this.props.respont.address,
      ToAddress: this.props.opponent,
      MessageText: message,
      MediaLink: JSON.stringify(preview),
      MessageTimestamp: Math.floor(Date.now() / 1000),
      isPending: true,
    };
    if (this.props.messages[this.props.opponent.toLowerCase()]) {
      pendingMessages = this.props.messages[this.props.opponent.toLowerCase()];
    }
    pendingMessages.push(pendingMessage);
    this.props.setMessage(this.props.opponent.toLowerCase(), pendingMessages);

    let mediaLink = [];
    if (files.length > 0) {
      this.setState({
        files: [],
        preview: [],
      });
      const formData = new FormData();

      files.forEach((file) => {
        file = new File([file], file.name);
        formData.append("files", file);
      });

      this.setState({
        uploading: true,
      });

      try {
        const result = await Axios.post(`${API}/files/upload`, formData);
        mediaLink = result.data.hash;
      } catch (error) {
        pendingMessage.isPending = false;
        pendingMessage.isError = "Error while uploading media";

        let pendingMessages =
          this.props.messages[this.props.opponent.toLowerCase()];
        pendingMessages = pendingMessages.filter(
          (message) =>
            !message.isPending &&
            message.FromAddress !== this.props.respont.address &&
            message.ToAddress !== pendingMessage.ToAddress &&
            message.MessageText !== pendingMessage.MessageText &&
            message.MediaLink !== pendingMessage.MediaLink &&
            message.MessageTimestamp !== pendingMessage.MessageTimestamp
        );
        pendingMessages.push(pendingMessage);
      }

      this.setState({
        uploading: false,
      });
    }

    if (mediaLink.length === files.length) {
      if (
        !this.props.connected ||
        (this.props.connected &&
          this.props.socketId &&
          this.props.room &&
          this.props.socketId === this.props.room)
      ) {
        try {
          await this.props.interactions.SendMessage(
            this.props.opponent,
            message,
            mediaLink
          );
        } catch (e) {
          console.log(e);

          const respont = await new Connect(
            this.props.respont.mnemonic()
              ? this.props.respont.mnemonic()
              : this.props.respont.privateKey(),
            chain.rpcUrls[0]
          );
          this.props.setRespont(respont);

          const interact = new Interactions(respont, true);
          this.props.setInteractions(interact);

          pendingMessage.isPending = false;
          pendingMessage.isError = "Error on on-chain transaction";

          let pendingMessages =
            this.props.messages[this.props.opponent.toLowerCase()];
          pendingMessages = pendingMessages.filter(
            (message) =>
              !message.isPending &&
              message.FromAddress !== this.props.respont.address &&
              message.ToAddress !== pendingMessage.ToAddress &&
              message.MessageText !== pendingMessage.MessageText &&
              message.MediaLink !== pendingMessage.MediaLink &&
              message.MessageTimestamp !== pendingMessage.MessageTimestamp
          );
          pendingMessages.push(pendingMessage);
        }
      } else {
        sendingMessage(message, mediaLink, this.props.opponent);
      }
    }
  };

  fetchMessage = async (beforeHeight = 0) => {
    try {
      this.setState({
        isFetching: true,
      });
      if (
        (ethers.utils.isAddress(this.props.opponent) &&
          !this.props.connected) ||
        (this.props.connected &&
          this.props.socketId &&
          this.props.room &&
          this.props.socketId === this.props.room)
      ) {
        let messages = await this.props.interactions.Message(
          this.props.opponent,
          beforeHeight
        );
        messages = messages.sort(
          (a, b) => a.MessageTimestamp - b.MessageTimestamp
        );

        if (messages.length === 0) {
          this.setState({ isZero: true });
        } else {
          this.setState({ isZero: false });
        }

        if (beforeHeight > 0) {
          if (this.props.messages[this.props.opponent.toLowerCase()])
            messages = [
              ...messages,
              ...this.props.messages[this.props.opponent.toLowerCase()],
            ];
          else messages = [...messages];
        }

        if (this.props.messages[this.props.opponent.toLowerCase()]) {
          messages = [
            ...messages.filter((message) => !message.isPending),
            ...this.props.messages[this.props.opponent.toLowerCase()].filter(
              (message) => message.isPending
            ),
          ];
        }

        messages = messages.sort(
          (a, b) => a.MessageTimestamp - b.MessageTimestamp
        );

        this.props.setMessage(this.props.opponent, messages);
      } else {
        chat(this.props.opponent);
      }
      this.setState({
        isFetching: false,
      });
    } catch (e) {
      console.log(e);

      const respont = await new Connect(
        this.props.respont.mnemonic()
          ? this.props.respont.mnemonic()
          : this.props.respont.privateKey(),
        chain.rpcUrls[0]
      );
      this.props.setRespont(respont);

      const interact = new Interactions(respont, true);
      this.props.setInteractions(interact);

      this.fetchMessage(beforeHeight);
    }
  };

  fileUploadHandler = (e) => {
    const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
    for (let i = 0; i < e.target.files.length; i++) {
      if (validImageTypes.includes(e.target.files[i].type)) {
        new Compressor(e.target.files[i], {
          quality: 0.4,
          success: (compressedResult) => {
            this.setState({
              files: [...this.state.files, compressedResult],
              preview: [
                ...this.state.preview,
                URL.createObjectURL(compressedResult),
              ],
            });
          },
        });
      }
    }

    e.target.value = null;
  };

  closeView = () => {
    if (this.state.showImage.length > 0) this.setState({ showImage: [] });
  };

  render() {
    return this.props.opponent ? (
      <>
        <ModalProfile
          show={this.props.opponentProfile ? true : false}
          picture={this.props.profiles[this.props.opponent]}
          opponent={this.props.opponent}
        />

        {this.props.opponentMedia && this.props.selectedMedia[0] >= 0 ? (
          <Image
            show={this.props.opponentMedia}
            isFetching={this.state.isFetching}
            history={this.props.history}
          />
        ) : null}

        <div className="dialog">
          <div className="row anti-block">
            <div className="profile-box col-md-8">
              <Link to="">
                <i
                  className="bi bi-arrow-left"
                  onClick={() => {
                    this.props.setOpponent("");
                  }}
                ></i>
              </Link>
              <Link
                to={`/${this.props.opponent}/profile`}
                className="rounded-circle profile-image"
              >
                <img
                  src={
                    this.props.profiles[this.props.opponent]
                      ? this.props.profiles[this.props.opponent]
                      : avatarBroken
                  }
                  alt={this.props.opponent}
                  className="rounded-circle"
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = avatarBroken;
                  }}
                  onDragStart={(event) => event.preventDefault()}
                />
              </Link>
              <ReactTooltip
                id="copy-text"
                place="bottom"
                effect="solid"
                type="light"
                className={"copy-tooltip"}
              >
                Click to Copy
              </ReactTooltip>
              <p
                className="cursor-pointer"
                title={this.props.opponent}
                data-tip
                data-for="copy-text"
                onClick={(e) =>
                  navigator.clipboard.writeText(e.target.textContent)
                }
              >
                {this.props.opponent}
              </p>
            </div>
          </div>
          <div className="bubble-box overflow-custom" ref={refBubble}>
            {this.state.isFetching ? (
              <div
                className="w-100 text-center text-secondary h6 mt-3 mb-3"
                ref={refLoading}
              >
                <div className="loader"></div>
                <span>We're getting your secure message.</span>
              </div>
            ) : null}
            {refBubble.current &&
            document.getElementsByClassName("bubble-list").length > 0 &&
            this.state.scrollPosition <
              refBubble.current.scrollHeight -
                refBubble.current.clientHeight -
                document
                  .getElementsByClassName("bubble-list")
                  [
                    document.getElementsByClassName("bubble-list").length - 1
                  ].getBoundingClientRect().height ? (
              <div className="scroll-down" onClick={this.handleScrollToBottom}>
                <i className="bi bi-chevron-down"></i>
              </div>
            ) : null}
            {this.props.messages[this.props.opponent.toLowerCase()]
              ? this.props.messages[this.props.opponent.toLowerCase()].map(
                  (message, i) => {
                    return (
                      <>
                        {i === 0 &&
                        message.BlockHeight > this.state.unreadBlock &&
                        this.state.unreadBlock > 0 ? (
                          <div className="unread">
                            <span>Unread Message(s)</span>
                          </div>
                        ) : this.state.unreadBlock > -1 &&
                          message.BlockHeight > this.state.unreadBlock &&
                          i > 0 &&
                          this.props.messages[
                            this.props.opponent.toLowerCase()
                          ][i - 1].BlockHeight <= this.state.unreadBlock ? (
                          <div className="unread">
                            <span>Unread Message(s)</span>
                          </div>
                        ) : null}
                        {i === 0 ? (
                          <div className="day px-5" data-tip data-for={`day-1`}>
                            <p>
                              {moment
                                .unix(message.MessageTimestamp)
                                .format("L")}
                            </p>
                          </div>
                        ) : moment
                            .unix(message.MessageTimestamp)
                            .format("Do/MM/YY") !==
                          moment
                            .unix(
                              this.props.messages[
                                this.props.opponent.toLowerCase()
                              ][i - 1].MessageTimestamp
                            )
                            .format("Do/MM/YY") ? (
                          <div className="day px-5" data-tip data-for={`day-1`}>
                            <p>
                              {moment
                                .unix(message.MessageTimestamp)
                                .format("L")}
                            </p>
                          </div>
                        ) : null}
                        <div className="bubble-list">
                          <Bubble
                            FromAddress={message.FromAddress}
                            ToAddress={message.ToAddress}
                            MessageText={message.MessageText}
                            MediaLink={message.MediaLink}
                            MessageTimestamp={message.MessageTimestamp}
                            isPending={
                              message.isPending ? message.isPending : false
                            }
                            isError={message.isError ? message.isError : false}
                            history={this.props.history}
                            messageIndex={i}
                          />
                        </div>
                      </>
                    );
                  }
                )
              : null}
          </div>
          {!this.state.isChecking ? (
            !this.props.hasKey[this.props.opponent.toLowerCase()] ? (
              <div className="has-key w-100 text-center text-light mb-3">
                <span className="d-block mb-2">
                  To send a message you need key to this address. Please create
                  a new one.
                </span>
                <button
                  className="btn btn-sm btn-outline-light"
                  onClick={this.createKey}
                  disabled={this.state.pendingCreateKey}
                >
                  <i className="bi bi-key-fill me-2"></i>
                  {this.state.pendingCreateKey
                    ? "Creating a key..."
                    : "Create key"}
                </button>
              </div>
            ) : !this.props.blocked.includes(this.props.opponent) ? (
              <div className="row">
                {this.state.files.length ? (
                  <div className="col-md-8 w-100 d-flex flex-wrap images-preview">
                    {this.state.preview.map((img, i) => {
                      return (
                        <div
                          className="rounded border border-secondary image-preview"
                          onClick={() => this.handleDeleteImage(i)}
                        >
                          <img
                            src={img}
                            alt={this.props.respont.address}
                            className="rounded"
                          />
                        </div>
                      );
                    })}
                  </div>
                ) : null}
                <div className="input-chat col-md-8">
                  <label
                    for="image-uploader"
                    className="bi bi-card-image"
                  ></label>
                  <input
                    type="text"
                    value={this.props.message}
                    onChange={this.chatInputHandler}
                    onKeyPress={(event) =>
                      event.key === "Enter" &&
                      (this.props.message.replace(/\s/g, "").length > 0 ||
                        this.state.files.length > 0)
                        ? this.sendingMessage()
                        : null
                    }
                    placeholder="Type something here..."
                    className="input-emoji"
                  />
                  <input
                    id="image-uploader"
                    type="file"
                    accept="image/*"
                    multiple
                    onChange={this.fileUploadHandler}
                  />
                  <button onClick={this.sendingMessage}>
                    <i class="bi bi-send-fill"></i>
                  </button>
                </div>
              </div>
            ) : null
          ) : null}
        </div>
      </>
    ) : null;
  }
}

const mapStateToProps = (state) => {
  return {
    respont: state.user.respont,
    interactions: state.user.interactions,
    tab: state.user.tab,
    opponent: state.user.opponent,
    updater: state.user.updater,
    localStorage: state.user.localStorage,
    messages: state.user.messages,
    selectedMedia: state.user.selectedMedia,
    hasKey: state.user.hasKey,
    profiles: state.user.profiles,
    connected: state.user.connected,
    socketId: state.user.socketId,
    room: state.user.room,
    message: state.user.message,
    blocked: state.user.blocked,
  };
};

const mapDispatchToProps = {
  setOpponent,
  setLocalStorage,
  setMessage,
  setMessaging,
  setHasKey,
  setRespont,
  setInteractions,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dialog);

import React from "react";

import Modal from "react-bootstrap/Modal";

class WaitingApprove extends React.Component {
  render() {
    return (
      <Modal show={this.props.show} contentClassName="rounded-circle" centered>
        <div className="modal-scan text-light">
          <i className="bi bi-hourglass-split"></i>
          <p className="mt-2">Waiting for confirmation...</p>
        </div>
      </Modal>
    );
  }
}

export default WaitingApprove;

import React from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Axios from "axios";
import { Connect, Interactions } from "@respont/app";

import Mnemonic from "./profile/Mnemonic";
import Blocked from "./profile/Blocked";

import { API, chain } from "../constants/API";

import avatarBroken from "../asset/avatar-broken.jpg";

import Modal from "react-bootstrap/Modal";

class ModalProfile extends React.Component {
  state = {
    file: {},
    preview: "",
  };

  redirect = () => {
    const { history } = this.props;

    return history.push(`/`);
  };

  fileUploadHandler = (e) => {
    var reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);

    reader.onloadend = function () {
      this.setState({
        preview: reader.result,
        file: e.target.files[0],
      });
    }.bind(this);
  };

  submitProfile = async () => {
    try {
      const formData = new FormData();

      formData.append("files", this.state.file);

      this.setState({
        uploading: true,
      });

      const result = await Axios.post(`${API}/files/upload`, formData);

      this.setState({
        uploading: false,
      });

      await this.props.interactions.ChangePicture(result.data.hash[0]);

      this.setState({
        file: "",
        preview: "",
      });
    } catch (e) {
      console.log(e);

      const respont = await new Connect(
        this.props.respont.mnemonic()
          ? this.props.respont.mnemonic()
          : this.props.respont.privateKey(),
        chain.rpcUrls[0]
      );
      this.props.setRespont(respont);

      const interact = new Interactions(respont, true);
      this.props.setInteractions(interact);

      this.submitProfile();
    }
  };

  closeModal = () => {
    if (!this.state.uploading)
      this.setState({
        file: "",
        preview: "",
        modalProfile: false,
      });
  };

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.redirect}
        contentClassName="rounded-circle"
        centered
      >
        <div className="modal-profile">
          <div className="profile">
            <label for="image-uploader">
              <img
                className="rounded-circle d-block cursor-pointer"
                src={
                  this.state.preview ? this.state.preview : this.props.profile
                }
                alt={this.state.address}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = avatarBroken;
                }}
                onDragStart={(event) => event.preventDefault()}
              />
            </label>
            <input
              type="file"
              id="image-uploader"
              accept="image/*"
              onChange={this.fileUploadHandler}
            />
            <div>
              <a
                href={`${chain.blockExplorerUrls}/address/${this.props.respont.address}`}
                target="_blank"
                rel="noopener noreferrer"
                title={this.props.respont.address}
              >
                {this.props.respont.address}
              </a>
              <div
                className={
                  this.state.file && this.state.preview ? "d-flex" : "d-none"
                }
              >
                <button
                  className="d-block rounded submit"
                  onClick={this.submitProfile}
                >
                  Submit
                </button>
                <button
                  className="d-block rounded close"
                  onClick={() => this.setState({ file: "", preview: "" })}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
          <div className="list">
            {(this.props.menu === "key" && this.props.respont) ||
            this.props.menu === "blocked" ? (
              <>
                <div className="header">
                  <Link to="/profile" className="back">
                    <i className="bi bi-arrow-left"></i>
                  </Link>
                  {this.props.menu}
                </div>
                {this.props.menu === "key" ? (
                  <Mnemonic respont={this.props.respont} />
                ) : this.props.menu === "blocked" ? (
                  <Blocked />
                ) : null}
              </>
            ) : (
              <>
                {this.props.respont ? (
                  <Link to="/profile/key" className="d-flex rounded">
                    <div className="bg-primary rounded">
                      <i className="bi bi-card-text"></i>
                    </div>
                    <p>Show Key</p>
                  </Link>
                ) : null}
                <Link to="#" className="d-flex rounded">
                  <div className="purple rounded">
                    <i className="bi bi-phone"></i>
                  </div>
                  <p>
                    Linked Devices{" "}
                    <span className="badge bg-warning text-dark">
                      Coming soon
                    </span>
                  </p>
                </Link>
                <Link to="/profile/notification" className="d-flex rounded">
                  <div className="bg-success rounded">
                    <i className="bi bi-bell"></i>
                  </div>
                  <p>
                    Notification
                    <span className="badge bg-warning text-dark">
                      Coming soon
                    </span>
                  </p>
                </Link>
                <Link to="/profile/blocked" className="d-flex rounded">
                  <div className="bg-danger rounded">
                    <i className="bi bi-x-circle-fill"></i>
                  </div>
                  <p>Blocked Address</p>
                </Link>
              </>
            )}
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    respont: state.user.respont,
    profile: state.user.profile,
  };
};

export default connect(mapStateToProps)(withRouter(ModalProfile));

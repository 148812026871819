import React from "react";
import { withRouter } from "react-router-dom";
import ReactGA from "react-ga4";

class RouterTracker extends React.Component {
  componentWillMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      ReactGA.set({ page: location.pathname });
    });
  }
  componentWillUnmount() {
    this.unlisten();
  }

  render() {
    return <div></div>;
  }
}

export default withRouter(RouterTracker);

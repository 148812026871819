import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import ReactGA from "react-ga4";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/js/bootstrap.js";
import "./asset/css/Chat.css";

import Home from "./pages/Home";
import RouterTracker from "./components/RouterTracker";

ReactGA.initialize("G-Q00F8L0G5Y");

class App extends React.Component {
  render() {
    return (
      <>
        <BrowserRouter>
          <Switch>
            <Route component={Home} path="/" exact />
            {/* <Route path="/sessions" exact>
            <Home connections />
          </Route> */}
            <Route path="/profile" exact>
              <Home profilePage />
            </Route>
            <Route path="/profile/:menu" exact>
              <Home profilePage />
            </Route>
            <Route component={Home} path="/:address" exact />
            <Route path="/:address/profile" exact>
              <Home opponentProfile />
            </Route>
            <Route path="/:address/media" exact>
              <Home opponentMedia />
            </Route>
          </Switch>
        <RouterTracker />
        </BrowserRouter>
      </>
    );
  }
}

export default App;

import React from "react";
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import moment from "moment";

import { setSelectMedia } from "../redux/actions/getData";

import avatarBroken from "../asset/avatar-broken.jpg";

class Image extends React.Component {
  state = {
    MediaLink: [],
    selectMedia: [],
    MediaLinkIndex: -1,
    isFetching: false,
  };

  componentDidMount() {
    this.fetchMedia();
  }

  fetchMedia = () => {
    let messages = this.props.messages[this.props.opponent.toLowerCase()];

    let MediaLink = [];
    messages.forEach((message, i) => {
      let MediaLinks = message.MediaLink;
      if (typeof message.MediaLink === "string") {
        MediaLinks = JSON.parse(message.MediaLink);
      }
      MediaLinks.forEach((mediaLink, j) => {
        MediaLink.push({ coordinate: [i, j], media: mediaLink });

        if (
          this.props.selectedMedia[0] === i &&
          this.props.selectedMedia[1] === j
        ) {
          this.setState({
            selectMedia: this.props.selectedMedia,
            MediaLinkIndex: MediaLink.length - 1,
          });
        }
      });
    });

    this.setState({
      MediaLink,
    });
  };

  changeSelectMedia = (i) => {
    if (i >= 0 && i < this.state.MediaLink.length) {
      this.setState({
        MediaLinkIndex: i,
        selectMedia: this.state.MediaLink[i].coordinate,
      });

      this.props.setSelectMedia(this.state.MediaLink[i].coordinate);
    }
  };

  redirect = () => {
    const { history } = this.props;

    this.props.setSelectMedia([-1, -1]);

    return history.push(`/${this.props.opponent}`);
  };

  doNothing = (e) => {
    e.stopPropagation();
  };

  download = (url) => {
    url = this.state.MediaLink[this.state.MediaLinkIndex].media;

    this.setState({
      isFetching: true,
    });

    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        this.setState({
          isFetching: false,
        });

        let name = url.split("/");
        name = name[name.length - 1];

        const blobURL = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = blobURL;
        a.style = "display: none";

        if (name && name.length) a.download = name;
        document.body.appendChild(a);
        a.click();
      })
      .catch((e) => console.log(e));
  };

  render() {
    return (
      <Modal
        show={this.props.show}
        fullscreen={true}
        className="modal-view"
        centered
      >
        {
          <div className="background" onClick={this.redirect}>
            <div
              className="header w-100 px-4 p-4 text-light d-flex justify-content-between"
              onClick={this.doNothing}
            >
              <div className="d-flex profile w-100">
                <img
                  src={avatarBroken}
                  alt={this.props.opponent}
                  className="rounded-circle"
                />
                <div className="opponent w-100 ms-2 mt-1">
                  <span className="address d-block">{this.props.opponent}</span>
                  <span className="d-block text-white-50">
                    {this.state.MediaLink.length >= 0 &&
                    this.state.selectMedia[0] >= 0
                      ? moment
                          .unix(
                            this.props.messages[
                              this.props.opponent.toLowerCase()
                            ][this.state.selectMedia[0]].MessageTimestamp
                          )
                          .format("MM/DD/YY hh:mma")
                      : "0 media found."}
                  </span>
                </div>
              </div>
              <div className="action d-flex flex-row-reverse w-100">
                {this.state.MediaLink.length > 0 ? (
                  <>
                    <i
                      className="bi bi-x-lg text-light cursor-pointer"
                      onClick={this.redirect}
                    ></i>
                    <i
                      className={`bi bi-download ${
                        !this.state.isFetching ? "text-light" : "text-muted"
                      } cursor-pointer`}
                      onClick={!this.state.isFetching ? this.download : null}
                    ></i>
                    <i
                      className="bi bi-chat-left-fill text-light cursor-pointer"
                      onClick={this.redirect}
                    ></i>
                  </>
                ) : null}
              </div>
            </div>
            <div className="body px-5">
              {this.state.MediaLink.length > 0 ? (
                this.state.MediaLinkIndex >= 0 ? (
                  <img
                    src={this.state.MediaLink[this.state.MediaLinkIndex].media}
                    alt={this.props.opponent}
                    onClick={this.doNothing}
                  />
                ) : (
                  <span className="text-light">
                    Oops, you have no media in this conversation.
                  </span>
                )
              ) : null}
            </div>
            {this.state.MediaLink.length >= 0 ? (
              <div className="footer w-100 text-center p-3">
                <div
                  className="d-flex justify-content-center overflow-custom"
                  onClick={this.doNothing}
                >
                  {this.state.MediaLink.length > 0 ? (
                    this.state.MediaLink.map((media, i) => {
                      return (
                        <div
                          className={`images ${
                            this.state.MediaLinkIndex === i
                              ? "image-active"
                              : "border border-secondary"
                          } rounded cursor-pointer`}
                        >
                          <img
                            src={media.media}
                            alt={this.props.opponent}
                            onClick={() => this.changeSelectMedia(i)}
                          />
                          ;
                        </div>
                      );
                    })
                  ) : (
                    <span className="text-light">
                      Oops, you have no media in this conversation.
                    </span>
                  )}
                </div>
              </div>
            ) : null}
          </div>
        }
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    opponent: state.user.opponent,
    messages: state.user.messages,
    selectedMedia: state.user.selectedMedia,
  };
};

const mapDispatchToProps = {
  setSelectMedia,
};

export default connect(mapStateToProps, mapDispatchToProps)(Image);
